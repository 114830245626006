import React, { useEffect, useState } from "react";
import Navbar1 from "./MenuNavbar";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
function DocumentMaster() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const [documentno, setdocumentno] = useState("");
  const [documenttype, setdocumenttype] = useState("");
  const [createdby, setCreatedby] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [documenttable, setdocumenttable] = useState([]);
  const [search, setSearch] = useState("");
  const state = useSelector((state) => state.changeTheState);
  const username = state?.user?.emailId;
  const [isloading, setisloading] = useState(true);
  const filteredData = documenttable.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["DOCUMENTNO", "DOCUMENTTYPE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    scrollToSection();
    setCreatedby(username?.split("@")[0].toUpperCase());
    getnewDocumentNo();
    getListOfPropertyDocumentMaster();
  }, []);

  const getnewDocumentNo = () => {
    axios
      .get(`${BASEURL}/NewDocumentId`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setdocumentno(response.data[0].NEXTNO);
      })
      .catch((error) => {
        console.error("Error fetching fuel station data:", error);
      });
  };

  const FetchTableValuesInFields = (item) => {
    setdocumentno(item.DOCUMENTNO);
    setdocumenttype(item.DOCUMENTTYPE);
  };

  const clearallinputs = () => {
    setdocumentno("");
    setdocumenttype("");
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const getListOfPropertyDocumentMaster = () => {
    axios
      .get(`${BASEURL}/ListOfPropertyDocumentMaster`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setdocumenttable(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error(
          "Error fetching List Of Property Document Master:",
          error
        );
        setisloading(false);
      });
  };

  async function SaveDocuments() {
    try {
      const response = await axios.post(
        `${BASEURL}/SaveDocument`,
        {
          DOCUMENTNO: documentno,
          DOCUMENTTYPE: documenttype,
          DOCUMENTCREATEDBY: createdby,
          DOCUMENTCREATEDDATE: date,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main">
              <h1 className="header__of__page">
                {menu ? menu : "Document Master"}
              </h1>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage DocumentMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Document No</label>
                  </p>
                  <div>
                    <input
                      required
                      className="inputttt"
                      value={documentno}
                      readOnly
                    ></input>
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Document Type</label>
                  </p>
                  <div>
                    <input
                      required
                      className="input_1"
                      value={documenttype}
                      onChange={(e) => {
                        setdocumenttype(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </aside>
              <div className="savebutton">
                <button
                  className="buttonunit2"
                  onClick={() => {
                    clearallinputs();
                    getnewDocumentNo();
                  }}
                >
                  New Record
                </button>
                <button
                  className="buttonunit"
                  onClick={async () => {
                    if (!documenttype) {
                      alert("Kindly Supply Document Type");
                    } else {
                      await SaveDocuments();
                      clearallinputs();
                      getnewDocumentNo();
                      getListOfPropertyDocumentMaster();
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>{" "}
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
      </div>
      <div className="service_details">
        <table className="table_1">
          <thead>
            <tr>
              <td>DOCUMENT NO</td>
              <td>DOCUMENT TYPE</td>
              <td>Edit</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td
                    className="w-[180px] text-right "
                    style={{ paddingRight: "80px" }}
                  >
                    {item.DOCUMENTNO ? item.DOCUMENTNO : ""}
                  </td>
                  <td>{item.DOCUMENTTYPE ? item.DOCUMENTTYPE : "NA"}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DocumentMaster;
