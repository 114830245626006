import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Navbar1 from "./MenuNavbar";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";

const UnitTypeMaster = () => {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [unittypeno, setunittypeno] = useState("");
  const [unittype, setunittype] = useState("");
  const [createdby, setCreatedby] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [documenttable, setdocumenttable] = useState([]);
  const [search, setSearch] = useState("");
  const state = useSelector((state) => state.changeTheState);
  const username = state.user.emailId;
  const [isloading, setisloading] = useState(true);
  const filteredData = documenttable.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["UNITTYPENO", "UNITTYPE"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    scrollToSection();
    setCreatedby(username.split("@")[0].toUpperCase());
    getUnitTypeMasterDetails();
    getnewUnitTypeNo();
  }, []);

  const getnewUnitTypeNo = () => {
    axios
      .get(`${BASEURL}/NewUnitTypeNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setunittypeno(response.data[0].NEXTNO);
      })
      .catch((error) => {
        console.error("Error fetching fuel station data:", error);
      });
  };

  const FetchTableValuesInFields = (item) => {
    setunittypeno(item.UNITTYPENO);
    setunittype(item.UNITTYPE);
  };

  const clearallinputs = () => {
    setunittypeno("");
    setunittype("");
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const getUnitTypeMasterDetails = () => {
    axios
      .get(`${BASEURL}/UnitTypeMasterDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setdocumenttable(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Unit Type Master:", error);
        setisloading(false);
      });
  };

  async function SaveUnitTypeMaster() {
    try {
      const response = await axios.post(
        `${BASEURL}/SaveUnitTypeMaster`,
        {
          UNITTYPENO: unittypeno,
          UNITTYPE: unittype,
          UNITTYPECREATEDBY: createdby,
          UNITTYPECREATEDDATE: date,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main">
              <h1 className="header__of__page">
                {menu ? menu : "Unit Type Master"}
              </h1>
            </header>
            <section className="section__newgarage">
              <aside className="aside__new__garage UnitTypeMaster__width">
                <div>
                  <p>
                    <label className="garage_id_">Unit Type No</label>
                  </p>
                  <div>
                    <input
                      required
                      className="input_1"
                      value={unittypeno}
                      readOnly
                    ></input>
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Unit Type</label>
                  </p>
                  <div>
                    <input
                      required
                      className="input_1"
                      value={unittype}
                      onChange={(e) => {
                        setunittype(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </aside>
              <div className="savebutton">
                <button
                  className="buttonunit2"
                  onClick={() => {
                    clearallinputs();
                    getnewUnitTypeNo();
                  }}
                >
                  New Record
                </button>
                <button
                  className="buttonunit"
                  onClick={async () => {
                    if (!unittype) {
                      alert("Kindly Supply Unit Type");
                    } else {
                      await SaveUnitTypeMaster();
                      clearallinputs();
                      getnewUnitTypeNo();
                      getUnitTypeMasterDetails();
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
      </div>
      <div className="service_detials">
        <table className="table_1 table__wifth__small">
          <thead>
            <tr>
              <td style={{ width: "150px", textAlign: "right" }}>UNIT NO</td>
              <td>UNIT TYPE</td>
              <td>EDIT</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td
                    style={{
                      width: "150px",
                      textAlign: "right",
                      paddingRight: "10px",
                    }}
                  >
                    {item.UNITTYPENO}
                  </td>
                  <td>{item.UNITTYPE}</td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UnitTypeMaster;
