import React, { useState, useEffect, useRef } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";

function TicketCreation() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [ticketid, setticketid] = useState("");
  const [ticketdate, setticketdate] = useState(new Date().toISOString());
  const [date, setdate] = useState(new Date().toISOString());
  const [commonareaorunit, setcommonareaorunit] = useState("");
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [selprojectid, setSelProjectId] = useState("");
  const [selprojectname, setselprojectname] = useState("");
  const [problemreported, setproblemreported] = useState("");
  const [client, setclient] = useState([]);
  const [selclientcode, setSelClientCode] = useState("");
  const [selclientname, setselclientname] = useState("");
  const [selunitid, setselunitid] = useState("");
  const [selunitno, setselunitno] = useState("");
  const [createdby, setCreatedBy] = useState("");
  const [ticketlist, setticketlist] = useState([]);
  const [unitlist, setlistunit] = useState([]);
  const [createddate, setCreatedDate] = useState(new Date().toISOString());
  const [search, setSearch] = useState("");
  const state = useSelector((state) => state.changeTheState);
  const loginAs = state?.user?.emailId?.split("@")[0];
  const [jobstatus, setjobstatus] = useState("");
  const [commonareaunitflag, setcommonareaunitflag] = useState(0);
  const [reportedby, setreportedby] = useState("");
  const [statuss, setstatus] = useState("");
  const [editflag, seteditflag] = useState(0);
  // const currentDate = new Date();
  // const firstDayOfMonth = new Date(
  //   currentDate.getFullYear(),
  //   currentDate.getMonth() + 0,
  //   2
  // );
  // console.log("First day of current month:", firstDayOfMonth);
  // const [startdatesort, setStartdatesort] = useState(
  //   firstDayOfMonth?.toISOString()?.split("T")[0]
  // );
  // const [enddatesort, setEnddatesort] = useState(
  //   currentDate?.toISOString()?.split("T")[0]
  // );

  const currentDate = new Date();
  const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 2);
  console.log("First day of current year:", firstDayOfYear);

  const [startdatesort, setStartdatesort] = useState(
    firstDayOfYear.toISOString().split("T")[0]
  );
  const [enddatesort, setEnddatesort] = useState(
    currentDate.toISOString().split("T")[0]
  );

  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [initialJobStatus, setInitialJobStatus] = useState(true);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const filesWithNames = files.map((file) => ({
      file,
      name: file.name,
    }));
    // Check file sizes
    // Check if any file exceeds the maximum size limit
    const exceedsSizeLimit = filesWithNames.some(
      (file) => file.file.size > 500 * 1024
    ); // 500KB in bytes

    if (exceedsSizeLimit) {
      alert(
        "Maximum file size limit is 500KB. Please select files within the limit."
      );
      // Reset file input value
      e.target.value = null;
      return; // Exit the function
    }
    setSelectedFiles([...selectedFiles, ...filesWithNames]);

    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews([...imagePreviews, ...previews]);
    // Reset file input value
    // e.target.value = null;
  };

  console.log(selectedFiles);

  const removeImage = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const updatedImagePreviews = [...imagePreviews];
    updatedImagePreviews.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
    setImagePreviews(updatedImagePreviews);
    fileInputRef.current.value = null;

    console.log(`Removed image: ${selectedFiles[index].name}`);
  };

  const [isloading, setisloading] = useState(true);

  useEffect(() => {
    scrollToSection();
    setCreatedBy(loginAs);
    getnewProjectid();
    getListOfProjectIds();
    getClientcode();
    ListOfTickets();
  }, []);

  const filteredData = ticketlist.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);
    const Date = item.TICKETDATE;
    const status = statuss;

    if (searchData.length === 0 && !startdatesort && !enddatesort && !status) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "TICKETID",
        "PROJECTNAME",
        "UNITNUMBER",
        "CLIENTNAME",
        "PROBLEMREPORTED",
        "REPORTEDBY",
        "TICKETSTATUS",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdatesort || Date >= startdatesort) &&
      (!enddatesort || Date <= enddatesort);

    const statusMatch =
      !statuss ||
      (statuss === "UNIT" ? !!item.COMMONAREAORUNIT : !item.COMMONAREAORUNIT);

    return textMatch && dateMatch && statusMatch;
  });

  const FetchTableValuesInFields = (item) => {
    seteditflag(1);
    setticketid(item.TICKETID);
    setticketdate(item.TICKETDATE);
    setcommonareaorunit(item.COMMONAREAORUNIT);
    setSelProjectId(item.PROPERTYID);
    setselprojectname(item.PROJECTNAME);
    setselunitid(item.UNITNO);
    setselunitno(item.UNITNUMBER);
    setSelClientCode(item.CLIENTCODE);
    console.log(item.CLIENTCODE);
    console.log(item.CLIENTCODE);
    setselclientname(item.CLIENTNAME);
    setproblemreported(item.PROBLEMREPORTED);
    setreportedby(item.REPORTEDBY);
    setjobstatus(item.TICKETSTATUS);
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const customStylesselect = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const clearallinputs = () => {
    setticketid("");
    setselunitno("");
    setticketdate(new Date().toISOString());
    setcommonareaorunit("");
    setSelProjectId("");
    setselprojectname("");
    setselunitid("");
    setSelClientCode("");
    setselclientname("");
    setproblemreported("");
    setreportedby("");
    setjobstatus("");
    setImagePreviews([]);
    setSelectedFiles([]);
  };

  function handleCreateTask(jobid, commonareaorunit, unitno, projectname) {
    navigate("/JobIdCreation", {
      state: { jobid, commonareaorunit, unitno, projectname },
    });
  }

  const getnewProjectid = () => {
    axios
      .get(`${BASEURL}/getjobidforpropertytickets`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setticketid(response.data[0].NEXTNO);
      })
      .catch((error) => {
        console.error("Error fetching Job Id:", error);
      });
  };

  const getListOfProjectIds = () => {
    axios
      .get(`${BASEURL}/ListOfProjectIds`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofprojectid(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Ids:", error);
      });
  };

  const ListOfTickets = () => {
    axios
      .get(`${BASEURL}/propertyticketlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setticketlist(response.data);
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Tickets:", error);
        setisloading(false);
      });
  };

  const getListOfUnitIds = (value) => {
    axios
      .post(
        `${BASEURL}/ListOfUnitIds`,
        {
          PROJECTID: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistunit(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Unit Ids:", error);
      });
  };

  const getClientcode = () => {
    axios
      .get(`${BASEURL}/Fetchingtheclientcode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setclient(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Client Code:", error);
      });
  };

  const handleUnitNo = (selectedOption) => {
    const selected = unitlist.find(
      (item) => item.UNITNO === selectedOption.value
    );
    setselunitid(selected.PROJECTUNIT);
  };

  const handleOptionChange = (selectedOption) => {
    const selected = client.find(
      (item) => item.CLIENTNAME === selectedOption.value
    );
    setSelClientCode(selected.CLIENTCODE);
  };

  const handleProject = (selectedOption) => {
    const selected = listofprojectid.find(
      (item) => item.PROJECTNAME === selectedOption.value
    );
    setSelProjectId(selected.PROJECTID);
    getListOfUnitIds(selected.PROJECTID);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }

  //   setSelectedFile(file);
  // };

  //   Save ----------------------------------------
  console.log("ticketid:", ticketid);
  console.log("ticketdate:", ticketdate);
  console.log("commonareaorunit:", commonareaorunit);
  console.log("selprojectid:", selprojectid);
  console.log("selunitid:", selunitid);
  console.log("selclientcode:", selclientcode);
  console.log("problemreported:", problemreported);
  console.log("reportedby:", reportedby);
  console.log("jobstatus:", jobstatus);
  console.log("date:", date);
  console.log("createdby:", createdby);
  async function Savedetails() {
    console.log("ticketid:", ticketid);
    console.log("ticketdate:", ticketdate);
    console.log("commonareaorunit:", commonareaorunit);
    console.log("selprojectid:", selprojectid);
    console.log("selunitid:", selunitid);
    console.log("selclientcode:", selclientcode);
    console.log("problemreported:", problemreported);
    console.log("reportedby:", reportedby);
    console.log("jobstatus:", jobstatus);
    console.log("date:", date);
    console.log("createdby:", createdby);
    try {
      const ticketResponse = await axios.post(
        `${BASEURL}/saveticketcreation`,
        {
          JOBID: ticketid,
          REPORTEDDATE: ticketdate,
          COMMONAREAORUNIT: commonareaorunit,
          PROPERTYID: selprojectid,
          UNITNO: selunitid,
          CLIENTCODE: selclientcode,
          PROBLEMREPORTED: problemreported,
          PROBLEMREPORTEDBY: reportedby,
          JOBSTATUS: jobstatus,
          JOBSTATUSDATE: date,
          JOBSTATUSUPDATEDBY: createdby,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(ticketResponse.data.message);

      // Image upload
      if (selectedFiles.length > 0) {
        const uploadImage = async (file) => {
          const formData = new FormData();
          formData.append("imageValue", file);
          const response = await axios.post(
            "https://marstrackbackend.azurewebsites.net/fileupload/uploadImage",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          return response.data; // Assuming the response contains the string you need
        };

        // Upload each file and retrieve their respective strings
        // selectedFiles.forEach((file) => {
        //   formData.append("imageValue", file);
        // });
        // const ImageString = await uploadImage(incidentImage);

        const ImageStrings = await Promise.all(
          selectedFiles.map((file) => uploadImage(file))
        );

        // Concatenate image strings into a single string (e.g., comma-separated)
        const ImageString = ImageStrings.join(",");

        const imageResponse = await axios.post(
          `${BASEURL}/attachimagetoTicket`,
          {
            JOBID: ticketid,
            DEFECTIMAGENAME: ImageString,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        alert(imageResponse.data.message);
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {menu ? menu : "Ticket Creation"}
                </h1>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Ticket ID</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={ticketid}
                        readOnly
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Ticket date</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        type="date"
                        value={ticketdate.split("T")[0]}
                        onChange={(e) => {
                          if (e.target.value > new Date().toISOString()) {
                            alert(
                              "Ticket date can not be greater than Current Date"
                            );
                          } else {
                            setticketdate(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">COMMON AREA OR UNIT</label>
                    </p>
                    <div>
                      <Select
                        styles={customStyless}
                        onChange={(e) => {
                          if (e.value === "") {
                            alert("Kindly Supply proper Type");
                            setcommonareaunitflag(0);
                            setcommonareaorunit("");
                          } else {
                            setcommonareaorunit(e.value);
                            if (e.value === "COMMON AREA") {
                              setcommonareaunitflag(1);
                              setselclientname("");
                              setselunitid("");
                            } else {
                              setcommonareaunitflag(0);
                            }
                          }
                        }}
                        value={
                          commonareaorunit
                            ? {
                                label: commonareaorunit,
                                value: commonareaorunit,
                              }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          { value: "COMMON AREA", label: "COMMON AREA" },
                          { value: "UNIT", label: "UNIT" },
                        ]}
                        isDisabled={editflag === 1}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Project Name</label>
                    </p>
                    <div>
                      <Select
                        styles={customStyless}
                        onChange={(selectedOption) => {
                          if (commonareaorunit) {
                            if (selectedOption.value === "") {
                              alert("Kindly Supply proper Project Name");
                              setselprojectname("");
                            } else {
                              setselprojectname(selectedOption.value);
                              handleProject(selectedOption);
                            }
                          } else {
                            alert("Kindly Supply Common area or Unit first");
                          }
                        }}
                        value={
                          selprojectname
                            ? {
                                label: selprojectname,
                                value: selprojectname,
                              }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          ...listofprojectid.map((item) => ({
                            value: item.PROJECTNAME,
                            label: item.PROJECTNAME,
                          })),
                        ]}
                        isDisabled={editflag === 1}
                      />
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Unit Number</label>
                    </p>
                    <div>
                      <Select
                        styles={customStyless}
                        onChange={(e) => {
                          if (commonareaorunit) {
                            if (e.value === "") {
                              alert("Kindly Supply proper Unit Id");
                              setselunitno("");
                            } else {
                              setselunitno(e.value);
                              handleUnitNo(e);
                            }
                          } else {
                            alert("Kindly Supply Common area or Unit first");
                          }
                        }}
                        value={
                          selunitno
                            ? { label: selunitno, value: selunitno }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          ...unitlist.map((item) => ({
                            value: item.UNITNO,
                            label: item.UNITNO,
                          })),
                        ]}
                        isDisabled={editflag === 1 || commonareaunitflag === 1}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Client Name </label>
                    </p>
                    <div>
                      <div>
                        <Select
                          styles={customStyless}
                          onChange={(e) => {
                            if (commonareaorunit) {
                              if (e.value === "") {
                                alert("Kindly supply proper Client Name");
                                setselclientname("");
                              } else {
                                setselclientname(e.value);
                                setreportedby(e.value);
                                handleOptionChange(e);
                              }
                            } else {
                              alert("Kindly Supply Common area or Unit first");
                            }
                          }}
                          value={
                            selclientname
                              ? { label: selclientname, value: selclientname }
                              : { label: "", value: "" }
                          }
                          options={[
                            { value: "", label: "" },
                            ...client.map((item) => ({
                              value: item.CLIENTNAME,
                              label: item.CLIENTNAME,
                            })),
                          ]}
                          isDisabled={
                            editflag === 1 || commonareaunitflag === 1
                          }
                        />
                      </div>
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Reported By</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={reportedby}
                        onChange={(e) => {
                          if (commonareaorunit) {
                            setreportedby(e.target.value);
                          } else {
                            alert("Kindly Supply Common area or Unit first");
                          }
                        }}
                        disabled={commonareaunitflag === 0}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Job Status</label>
                    </p>
                    <div>
                      <div>
                        <Select
                          styles={customStyless}
                          onChange={(e) => {
                            if (commonareaorunit) {
                              if (e.value === "") {
                                alert("Kindly choose proper job status");
                              } else {
                                setjobstatus(e.value);
                              }
                            } else {
                              alert("Kindly Supply Common area or Unit first");
                            }
                          }}
                          value={
                            jobstatus
                              ? {
                                  label: jobstatus,
                                  value: jobstatus,
                                }
                              : { label: "", value: "" }
                          }
                          options={
                            initialJobStatus
                              ? [
                                  { value: "", label: "" },
                                  { value: "LOGGED IN", label: "LOGGED IN" },
                                ]
                              : [
                                  { value: "", label: "" },
                                  { value: "LOGGED IN", label: "LOGGED IN" },
                                  {
                                    value: "WORK IN PROGRESS",
                                    label: "WORK IN PROGRESS",
                                  },
                                  { value: "CLOSED", label: "CLOSED" },
                                ]
                          }
                        />
                      </div>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Problem Reported</label>
                    </p>
                    <div>
                      <textarea
                        style={{
                          maxWidth: "300px",
                          minWidth: "300px",
                          border: "1px solid black",
                          minHeight: "100px",
                          maxHeight: "100px",
                        }}
                        className="input_1"
                        required
                        value={problemreported}
                        onChange={(e) => {
                          if (commonareaorunit) {
                            setproblemreported(e.target.value);
                          } else {
                            alert("Kindly Supply Common area or Unit first");
                          }
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Upload Image</label>
                    </p>
                    {/* <div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => handleFileChange(e)}
                      />
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "200px",
                            marginTop: "10px",
                          }}
                        />
                      )}
                    </div> */}
                    <div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/jpeg, image/png, image/jpg"
                        multiple
                        onChange={(e) => handleFileChange(e)}
                      />
                      <div className="flex gap-2 flex-wrap">
                        {imagePreviews.map((preview, index) => (
                          <div key={index} className="">
                            <img
                              src={preview}
                              alt="Preview"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "50px",
                                marginTop: "10px",
                              }}
                            />
                            <button onClick={() => removeImage(index)}>
                              Remove
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>
                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      clearallinputs();
                      seteditflag(0);
                      setcommonareaunitflag(0);
                      getnewProjectid();
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      if (commonareaunitflag === 1) {
                        if (!ticketid) {
                          alert("Kindly supply Ticket Id");
                        } else if (!commonareaorunit) {
                          alert("Kindly supply Common area or unit");
                        } else if (!selprojectname) {
                          alert("Kindly Supply Project Name");
                        } else if (!problemreported) {
                          alert("Kindly Provide Problem to be reported");
                        } else if (!reportedby) {
                          alert("Kindly supply Reported By");
                        } else if (!jobstatus) {
                          alert("Kindly Supply Job Status");
                        } else {
                          await Savedetails();
                          seteditflag(0);
                          setcommonareaunitflag(0);
                          clearallinputs();
                          getnewProjectid();
                          getListOfProjectIds();
                          getClientcode();
                          ListOfTickets();
                        }
                      } else if (commonareaunitflag === 0) {
                        if (!ticketid) {
                          alert("Kindly supply Ticket Id");
                        } else if (!commonareaorunit) {
                          alert("Kindly supply Common area or unit");
                        } else if (!selprojectname) {
                          alert("Kindly Supply Project Name");
                        } else if (!selunitid) {
                          alert("Kindly Supply Unit Id");
                        } else if (!selclientname) {
                          alert("Kindly Supply Client name");
                        } else if (!problemreported) {
                          alert("Kindly Provide Problem to be reported");
                        } else if (!reportedby) {
                          alert("Kindly supply Reported By");
                        } else if (!jobstatus) {
                          alert("Kindly Supply Job Status");
                        } else {
                          await Savedetails();
                          seteditflag(0);
                          setcommonareaunitflag(0);
                          clearallinputs();
                          getnewProjectid();
                          getListOfProjectIds();
                          getClientcode();
                          ListOfTickets();
                        }
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>

      <main className="lease__bothfilte">
        <div className="searc__lease">
          <p>Search:</p>
          <input
            className="w-[400px] ml-[10px]"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          <div className="search__icon__lease">
            {" "}
            <FaSearch />
          </div>
          <p>Common Area Or Unit:</p>
          <div className="w-[200px]">
            {" "}
            <Select
              styles={customStylesselect}
              onChange={(e) => {
                if (e.value === "") {
                  alert("Kindly Supply proper Status");
                  setstatus("");
                } else {
                  setstatus(e.value);
                }
              }}
              value={
                statuss
                  ? { label: statuss, value: statuss }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                { value: "COMMON AREA", label: "COMMON AREA" },
                { value: "UNIT", label: "UNIT" },
              ]}
            />
          </div>
        </div>
      </main>
      <main className="lease__bothfilte">
        <aside className="startand__enddate__lease ">
          <div>
            <p>
              <label className="">Start date</label>
            </p>
            <div>
              <input
                required
                className="input_1"
                type="date"
                value={startdatesort.split("T")[0]}
                onChange={(e) => setStartdatesort(e.target.value)}
              />
            </div>
          </div>
          <div>
            <p>
              <label className="">End Date</label>
            </p>
            <div>
              <input
                className="input_1"
                type="date"
                required
                value={enddatesort.split("T")[0]}
                onChange={(e) => setEnddatesort(e.target.value)}
              />
            </div>
          </div>
        </aside>
      </main>
      <div className="service_detials ">
        <table className="table_1">
          <thead>
            <tr>
              <td style={{ textAlign: "right" }}>TICKETID</td>
              <td>TICKETDATE</td>
              <td>COMMON AREA OR UNIT</td>
              <td style={{ textAlign: "right" }}>PROPERTY NAME</td>
              <td style={{ textAlign: "right" }}>UNIT NO</td>
              <td>CLIENT NAME</td>
              <td>PROBLEM REPORTED</td>
              <td>REPORTED BY</td>
              <td>TICKET STATUS</td>
              <td>EDIT</td>
              <td>CREATE TASK</td>
              <td>CUSTOMER FEED BACK</td>
              <td>CUSTOMER RATING</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                style={{
                  textTransform: "uppercase",
                }}
              >
                <td style={{ textAlign: "right" }}>
                  {item.TICKETID ? item.TICKETID : ""}
                </td>
                <td style={{ width: "130px" }}>
                  {item.TICKETDATE
                    ? moment(item.TICKETDATE).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td style={{ textAlign: "right" }}>
                  {item.COMMONAREAORUNIT ? item.COMMONAREAORUNIT : "NA"}
                </td>
                <td style={{ textAlign: "right" }}>
                  {item.PROJECTNAME ? item.PROJECTNAME : ""}
                </td>
                <td style={{ textAlign: "right" }}>
                  {item.UNITNUMBER ? item.UNITNUMBER : ""}
                </td>
                <td>{item.CLIENTNAME ? item.CLIENTNAME : "NA"}</td>
                <td>{item.PROBLEMREPORTED ? item.PROBLEMREPORTED : "NA"}</td>
                <td>{item.REPORTEDBY ? item.REPORTEDBY : "NA"}</td>
                <td>{item.TICKETSTATUS ? item.TICKETSTATUS : "NA"}</td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    FetchTableValuesInFields(item);
                    scrollToSection();
                    setInitialJobStatus(false);
                  }}
                >
                  <BiSolidEditAlt />
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    handleCreateTask(
                      item.TICKETID,
                      item.COMMONAREAORUNIT,
                      item.UNITNUMBER,
                      item.PROJECTNAME
                    );
                  }}
                >
                  <FaTasks />
                </td>
                <td>{item.CUSTOMERFEEDBACK ? item.CUSTOMERFEEDBACK : "N/A"}</td>
                <td>{item.RATING ? item.RATING : "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TicketCreation;
