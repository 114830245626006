import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import Select from "react-select"; // Import react-select
import { useNavigate, useLocation } from "react-router-dom";
const UnoccupiedUnit = () => {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [search, setSearch] = useState("");
  const [UnoccupiedUnit, setUnoccupiedUnit] = useState([]);
  const [isloading, setisloading] = useState(true);

  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth?.toISOString()?.split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );

  useEffect(() => {
    masterData();
  }, []);

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = UnoccupiedUnit?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (searchData.length === 0) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        ["PROJECTID"],
        ["PROJECTNAME"],
        ["UNITID"],
        ["UNITNO"],
        ["SUBUNITS"],
        ["UNITTYPE"],
        ["ROOMS"],
        ["LOCATION"],
        ["RATE"],
        ["PROJECTID"],
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    // const isDateRangeMatch =
    //   (!fromDateFilter ||
    //     new Date(item?.BILLDATE) >= new Date(fromDateFilter)) &&
    //   (!toDateFilter || new Date(item?.BILLDATE) <= new Date(toDateFilter));

    return textMatch;
    // && isDateRangeMatch;
  });

  function masterData() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getUnoccupiedUnitDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        // Sort the data by PROJECTID in ascending order
        const sortedData = response.data.sort(
          (a, b) => a.PROJECTID - b.PROJECTID
        );
        setUnoccupiedUnit(sortedData);
        setisloading(false);
        // setUnoccupiedUnit(response.data);
        // console.log(response.data);
      });
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="toHIde__when__Print">
        <MenuNavbar />
        <div>
          <header className="header__of__main lg:flex justify-between px-3 items-center">
            <h1 className="header__of__page">
              {menu ? menu : "Unoccupied Unit"}
            </h1>
            {/* <button
              className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
              onClick={() => {
                navigate("/RentAmountCalculation");
              }}
            >
              Back
            </button> */}
          </header>

          <article>
            <div className="flex gap-3 relative mx-[30px] my-2"></div>
            <div className="lg:flex items-center justify-between mx-[20px]">
              <div className="flex">
                <p className="lg:mx-2 text-[16px]">Search</p>
                <input
                  className="w-[300px] lg:mr-[50px] h-[30px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              {/* <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
                <div className="lg:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
                  <input
                    type="date"
                    value={fromDateFilter}
                    onChange={handleFromDateFilterChange}
                  />
                </div>
                <div className="lf:ml-[10px] md:ml-0 flex items-center ">
                  <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
                  <input
                    type="date"
                    value={toDateFilter}
                    onChange={handleToDateFilterChange}
                  />
                </div>
              </section> */}
            </div>

            <div className="  overflow-auto m-[10px]  p-[20px]">
              <table className="table_1" style={{ fontSize: "12px" }}>
                <thead>
                  <tr style={{ fontWeight: "400" }}>
                    <td className="text-right">PROJECT ID</td>
                    <td>PROJECT DESCRIPTION </td>{" "}
                    <td className="text-right">UNIT ID</td>
                    <td className="text-right">UNIT NO</td>
                    <td className="text-right">SUBUNIT NO</td>
                    <td className="text-right">SUB UNITS</td>
                    <td>UNIT TYPE</td>
                    <td className="text-right"> ROOMS</td>
                    <td>LOCATION</td>
                    <td className="text-right">RATE</td>
                    {/* <td>OCCUPIED BY</td>
                    <td>OCCUPIED DATE</td> */}
                    {/* <td>CREATED DATE</td>
                    <td>CREATED BY</td>
                    <td>PROJECT NAME </td> */}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData.map((item, index) => (
                      <tr key={index} className="table-row">
                        {/* <td  >{item.BILLNO || "N/A"}</td> */}
                        <td className="text-right">
                          {item?.PROJECTID ? item?.PROJECTID : "N/A"}{" "}
                        </td>
                        <td>
                          {item?.PROJECTNAME ? item?.PROJECTNAME : "N/A"}{" "}
                        </td>
                        <td className="text-right">
                          {item?.UNITID ? item?.UNITID : "N/A"}{" "}
                        </td>
                        <td className="text-right">
                          {item?.UNITNO ? item?.UNITNO : "N/A"}{" "}
                        </td>
                        <td className="text-right">
                          {item?.SUBUNITNO ? item?.SUBUNITNO : "N/A"}{" "}
                        </td>
                        <td className="text-right">
                          {item?.SUBUNITS ? item?.SUBUNITS : "N/A"}{" "}
                        </td>{" "}
                        <td>{item?.UNITTYPE ? item?.UNITTYPE : "N/A"} </td>
                        <td className="text-right">
                          {item?.ROOMS ? item?.ROOMS : "N/A"}{" "}
                        </td>
                        <td>{item?.LOCATION ? item?.LOCATION : "N/A"} </td>
                        <td className="text-right">
                          {item?.RATE?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default UnoccupiedUnit;
