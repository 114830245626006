import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import "../PropertyManagementCss/elctricpage.css";
import MenuNavbar from "./MenuNavbar";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { unAllocatedRec } from "../../Redux/Action/unAllocateReceipts";
const getLastDayOfMonth = (year, month) => {
  // Return the first day of the next month
  return new Date(year, month + 1, 1);
};

const getCurrentDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Get the first day of the current month (day = 01)
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);

  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${currentYear}-${(currentMonth + 1)
    .toString()
    .padStart(2, "0")}-01`;

  return formattedDate;
};

function UnConfirmedLeases() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [selectedRows, setSelectedRows] = useState([]);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [client, setclient] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selclientcode, setSelClientCode] = useState("");
  const [selclientname, setselclientname] = useState("");
  const [selprojectname, setselprojectname] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const [clientName, setClientName] = useState([]);
  const [listOfTrans, setlistOfTrans] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [Rentdetails, setRentdetails] = useState([]);
  const [Days, setDays] = useState("");
  const [daysDifference, setDaysDifference] = useState([]);
  const [BillNumber, setBillNumber] = useState("");
  const [Startdatesort, setStartdatesort] = useState(getCurrentDate());
  const state = useSelector((state) => state.changeTheState);
  console.log(state);
  const loginAs = state?.user?.emailId?.split("@")[0];
  const getClientcode = () => {
    axios
      .get(`${BASEURL}/getclientsnotattachedkyc`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setclient(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
    axios
      .get(`${BASEURL}/ListOfPropertyunitleases`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        const nullConfirmedData = response.data.filter(
          (item) => item.CONFIRMED === null
        ); // Filter where CONFIRMED is null

        setlistOfTrans(nullConfirmedData);
        console.log(nullConfirmedData);
      })

      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });

    axios
      .get(`${BASEURL}/clientlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        console.log(response.data);
        setClientName(response.data);
        // console.log(response.data[0]?.NEXTNO);
      })
      .catch((err) => {
        alert("Server error");
      });
  };

  useEffect(() => {
    getClientcode();
  }, []);
  const [search, setSearch] = useState("");

  const currentDateFilter = new Date();
  const firstDayOfMonth = new Date(
    currentDateFilter.getFullYear(),
    currentDateFilter.getMonth() + 0,
    2
  );
  console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const filteredData = listOfTrans?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (searchData.length === 0 && !selectedClientName) return true;

    const textMatch = searchData.every((term) => {
      return [
        "CLIENTCODE",
        "CLIENTNAME",
        "UNITID",
        "UNITNUMBER",
        "LEASENUMBER",
        "SUBUNITNO",
        "RENTAMOUNT",
        "DEPOSITAMOUNT",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const clientMatch =
      !selectedClientName ||
      item["CLIENTNAME"]?.toLowerCase() === selectedClientName?.toLowerCase();

    return textMatch && clientMatch;
  });

  const handleClientNameChange = (selectedOption) => {
    setSelectedClientName(selectedOption?.value);
  };
  function handleProcesToAllocate(statement) {
    dispatch(unAllocatedRec(statement));
    navigate("/TENANTKYC");
  }

  async function Confirmdetails() {
    try {
      const requests = selectedRows.map((item) =>
        axios.post(
          `${BASEURL}/Updatethedetailsofpropertyleases`,
          {
            PROJECTID: item.PROJECTID, // Adjust based on your item structure
            PROJECTUNITID: item.UNITID,
            PROJECTUNITLEASENO: item.LEASENUMBER,
            LEASENUMBER: item.LEASENUMBER,
            CLIENTCODE: item.CLIENTCODE,
            CONFIRMEDBY: loginAs,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
      );

      // Wait for all requests to complete
      const responses = await Promise.all(requests);

      // Extract messages from responses and show a single alert
      const messages = responses.map((response) => response.data.message);
      alert("Lease Confirmed Successfully");
    } catch (error) {
      alert(error.response?.data?.message || "An error occurred");
    }
  }

  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      // Add item to selectedRows if it's checked
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, item]);
    } else {
      // Remove item from selectedRows if it's unchecked
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== item)
      );
    }
  };

  console.log(selectedRows);
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  return (
    <div className="">
      <MenuNavbar />

      <header className="header__of__main flex justify-between items-center lg:mx-[20px]">
        <h1 className="header__of__page">
          {menu ? menu : "UnConfirmed Leases"}
        </h1>

        <button
          className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
          onClick={() => {
            navigate("/PropertyUnitLeases");
          }}
        >
          Back
        </button>
      </header>
      <div className="lg:flex items-center gap-[20px] m-[20px]">
        <div className="flex">
          <p className="lg:mx-2 text-[16px] font-bold">Search : </p>
          <input
            className="w-[300px] lg:mr-[50px] h-[30px]"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
        </div>
        <div className="flex justify-center items-center">
          <p style={{ width: "300px" }}>
            <label className=" ">Filter by Client Name : </label>
          </p>
          <div className="  w-[100%]" style={{ zIndex: 500 }}>
            <Select
              styles={customStyless}
              value={{
                value: selectedClientName,
                label: selectedClientName,
              }}
              className=" "
              options={[
                { value: "", label: "Select Client.." },
                ...clientName.map((item) => ({
                  value: item["CLIENTNAME"],
                  label: item["CLIENTNAME"],
                })),
              ]}
              onChange={handleClientNameChange}
            />
          </div>
        </div>
        {/* clientName.map((item) => ({
                  value: item["CLIENTNAME"],
                  label: item["CLIENTNAME"],
                }))) */}
        {/* <section className="lg:flex gap-3 items-center  lg:px-[20px] text-[16px]">
          <div className="lg:ml-[10px] md:ml-0 flex items-center ">
            <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
            <input
              type="date"
              value={fromDateFilter}
              onChange={handleFromDateFilterChange}
            />
          </div>
          <div className="lf:ml-[10px] md:ml-0 flex items-center ">
            <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
            <input
              type="date"
              value={toDateFilter}
              onChange={handleToDateFilterChange}
            />
          </div>
        </section> */}
      </div>
      <section className="section__newgarage">
        <aside className="aside__new__garage UnitTypeMaster__width">
          {/* <div style={{ width: "202px" }}>
                  <p style={{ width: "140px" }}>
                    <label style={{ width: "140px" }}>BILL&nbsp;NO: </label>
                  </p>
                  <div className="ml-4 text-red-600 font-bold">
                    {BillNumber}
                  </div>
                </div> */}
          {/* <div>
                  <p>
                    <label className="garage_id_">Project Name : </label>
                  </p>
                  <div>
                    <Select
                      styles={customStyless}
                      onChange={(selectedOption) => {
                        if (selectedOption.value === "") {
                          alert("Kindly Supply proper Project Name");
                          setselprojectname("");
                        } else {
                          setselprojectname(selectedOption.value);
                          //   handleProject(selectedOption);
                        }
                      }}
                      value={
                        selprojectname
                          ? {
                              label: selprojectname,
                              value: selprojectname,
                            }
                          : { label: "", value: "" }
                      }
                      options={[
                        { value: "", label: "" },
                        ...listofprojectid.map((item) => ({
                          value: item.PROJECTNAME,
                          label: item.PROJECTNAME,
                        })),
                      ]}
                    />
                  </div>
                </div> */}

          {/* <div style={{ width: "300px" }}>
                  <p>
                    <label className="garage_id_">Date</label>
                  </p>
                  <div>
                    <input
                      style={{ width: "200px" }}
                      type="date"
                      onChange={handleDateChange}
                      value={Startdatesort}
                    ></input>
                  </div>
                </div> */}
        </aside>
      </section>
      <div className="flex justify-end mb-1 mr-6">
        <button className="button" onClick={() => Confirmdetails()}>
          Confirm
        </button>
      </div>
      {isloading ? (
        <Loading />
      ) : (
        <div className="mx-[0.8]px flex justify-center w-full items-start">
          <table className="table_1 " style={{ fontSize: "12px" }}>
            <thead>
              <tr style={{ textTransform: "uppercase" }}>
                <td></td>
                <td>CLIENT CODE </td>
                <td>CLIENT NAME</td>
                {/* <td>UNITID</td>
                <td className="">SUB UNITID</td> */}
                <td className="text-right"> UNIT NUMBER</td>
                <td className="text-right"> SUB UNIT NO</td>
                <td>LEASENUMBER </td>
                <td>LEASE START DATE </td>
                <td>LEASE END DATE </td>
                <td>RENT AMOUNT</td>
                <td className="text-right">DEPOSIT AMOUNT</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index} style={{ textTransform: "uppercase" }}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item)}
                        onChange={(e) =>
                          handleCheckboxChange(item, e.target.checked)
                        }
                      ></input>
                    </td>
                    <td>{item.CLIENTCODE ? item.CLIENTCODE : "NA"}</td>
                    <td>{item.CLIENTNAME ? item.CLIENTNAME : "N/A"}</td>
                    {/* <td>{item.UNITID ? item.UNITID : "NA"}</td>
                    <td>{item.SUBUNITID ? item.SUBUNITID : "NA"}</td> */}
                    <td className="text-right">
                      {item.UNITNUMBER ? item.UNITNUMBER : "NA"}
                    </td>
                    <td className="">
                      {item.SUBUNITNO ? item.SUBUNITNO : "NA"}
                    </td>
                    <td className="text-right">
                      {item.LEASENUMBER ? item.LEASENUMBER : "0.00"}
                    </td>
                    <td className="text-right">
                      {item.LEASESTARTDATE
                        ? item?.LEASESTARTDATE.slice(8, 10) +
                          "/" +
                          item?.LEASESTARTDATE.slice(5, 7) +
                          "/" +
                          item?.LEASESTARTDATE.slice(0, 4)
                        : "NA"}
                    </td>
                    <td className="text-right">
                      {item.LEASEENDDATE
                        ? item?.LEASEENDDATE.slice(8, 10) +
                          "/" +
                          item?.LEASEENDDATE.slice(5, 7) +
                          "/" +
                          item?.LEASEENDDATE.slice(0, 4)
                        : "NA"}
                    </td>
                    <td className="text-right">
                      {item.RENTAMOUNT ? item.RENTAMOUNT : "0.00"}
                    </td>
                    <td className="text-right">
                      {item.DEPOSITAMOUNT ? item.DEPOSITAMOUNT : "0.00"}
                    </td>

                    {/* <td className="text-center">
                      <button
                        onClick={() => {
                          handleProcesToAllocate(item);
                        }}
                      >
                        Attach
                      </button>
                    </td> */}

                    {/* <td className="w-[300px]">
                      <Select
                        onChange={(e) => {
                          item.SELCLIENT = e.value;
                        }}
                        
                        options={client.map((clientItem) => ({
                          value: clientItem?.CLIENTCODE,
                          label: clientItem?.CLIENTNAME,
                        }))}
                      />
                    </td> */}
                    {/*   <td className="text-right">
                      {item.LEASEENDDATE
                        ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                        : "NA"}
                    </td>

                    <td
                      // className="text-right"
                      className={`uppercase text-right ${
                        item.RENTAMOUNT === 0 ? "bg-red-200" : ""
                      }`}
                      title={item.RENTAMOUNT === 0 ? "Rent Amount is zero" : ""}
                    >
                      {item?.RENTAMOUNT?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-right">
                      {daysDifference[index] !== undefined &&
                      Startdatesort?.slice(5, 7) ===
                        item.LEASESTARTDATE?.slice(5, 7) &&
                      Startdatesort?.slice(0, 4) ===
                        item.LEASESTARTDATE?.slice(0, 4)
                        ? (
                            (item.RENTAMOUNT /
                              moment(item.LEASESTARTDATE).daysInMonth()) *
                            daysDifference[index]
                          ).toFixed(2)
                        : item?.RENTAMOUNT?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                     
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default UnConfirmedLeases;
