import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Navbar1 from "./MenuNavbar";
import axios from "axios";
import "../PropertyManagementCss/UnitMaster.css";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";

function SubUnitMaster() {
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const [subunitmastertable, setsubunitmastertable] = useState([]);
  const [listofprojectnames, setlistofprojectnames] = useState([]);
  const [unitid, setunitid] = useState("");
  const [selunitno, setselunitno] = useState("");
  const [subunitId, setsubunitId] = useState("");
  const [subunitnumber, setsubunitnumber] = useState("");
  const [createdby, setcreatedby] = useState("");
  const [selprojectname, setselprojectname] = useState("");
  const [projectid, setprojectid] = useState("");
  const [search, setSearch] = useState("");
  const state = useSelector((state) => state.changeTheState);
  const username = state?.user?.emailId;
  const [isloading, setisloading] = useState(true);
  const [tableflag, settableflag] = useState(0);
  const [unitlist, setlistunit] = useState([]);
  const [date, setDate] = useState(new Date().toISOString());

  const filteredData = subunitmastertable.filter((item) => {
    const searchData = search?.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTID",
        "PROJECTNAME",
        "UNITNUMBER",
        "UNITID",
        "SUBUNITID",
        "SUBUNITNO",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    scrollToSection();
    setcreatedby(username?.split("@")[0].toUpperCase());
    getListOfProjectIds();
    getListOfUnitsUnderProperties();
  }, []);

  const FetchTableValuesInFields = (item) => {
    settableflag(1);
    setselprojectname(item.PROJECTNAME);
    setprojectid(item.PROJECTID);
    setunitid(item.UNITID);
    setselunitno(item.UNITNUMBER);
    setsubunitId(item.SUBUNITID);
    setsubunitnumber(item.SUBUNITNO);
  };

  const clearallinputs = () => {
    setprojectid("");
    setselprojectname("");
    setunitid("");
    setselunitno("");
    setsubunitId("");
    setsubunitnumber("");
  };

  const getNewSubUnitId = (projectid, unitid) => {
    axios
      .post(
        `${BASEURL}/getsubunitno`,
        {
          PROJECTID: projectid,
          UNITID: unitid,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setsubunitId(response.data[0].SUBUNITID);
      })
      .catch((error) => {
        alert(error.response.data.message);
        setselprojectname("");
        setprojectid("");
        setselunitno("");
        setunitid("");
        setsubunitId("");
        console.log("Error While fetching New Sub Unit Id", error);
      });
  };

  const getNewUnitId = (value) => {
    axios
      .post(
        `${BASEURL}/ListOfUnitIds`,
        {
          PROJECTID: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistunit(response.data);
      })
      .catch((error) => {
        console.log("Error While fetching New Unit Id", error);
      });
  };

  const getListOfProjectIds = () => {
    axios
      .get(`${BASEURL}/ListOfProjectIds`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofprojectnames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Ids:", error);
      });
  };

  const getListOfUnitsUnderProperties = () => {
    axios
      .get(`${BASEURL}/getsubunitlist`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setsubunitmastertable(response.data);
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Units Under Properties:", error);
        setisloading(false);
      });
  };

  async function SaveSubUnits() {
    try {
      const response = await axios.post(
        `${BASEURL}/savesubunits`,
        {
          PROJECTID: projectid,
          PROJECTUNITID: unitid,
          PROJECTSUBUNITNO: subunitId,
          PROJECTSUBUNITNUMBER: subunitnumber,
          PROJECTSUBUNITCREATEDBY: createdby,
          PROJECTSUBUNITCREATEDDATE: date,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const handleUnitNo = (selectedOption) => {
    const selected = unitlist.find(
      (item) => item.UNITNO === selectedOption.value
    );
    setunitid(selected.PROJECTUNIT);
    getNewSubUnitId(projectid, selected.PROJECTUNIT);
  };

  const handleProject = (selectedOption) => {
    const selected = listofprojectnames.find(
      (item) => item.PROJECTNAME === selectedOption.value
    );
    getNewUnitId(selected.PROJECTID);
    setprojectid(selected.PROJECTID);
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="center-container_1">
        <div className="form-container_1  ">
          <main className="form_info ">
            <header className="header__of__main">
              <h1 className="header__of__page">
                {menu ? menu : "Sub Unit Master"}
              </h1>
            </header>

            <section className="section__newgarage">
              <aside className="aside__new__garage PropertyUnitLeases__width">
                <div>
                  <p>
                    <label className="garage_id_">Project Name</label>
                  </p>
                  <div>
                    <Select
                      styles={customStyless}
                      onChange={(selectedOption) => {
                        if (selectedOption.value === "") {
                          alert("Kindly Supply proper Project Name");
                          setselprojectname("");
                        } else {
                          setselprojectname(selectedOption.value);
                          handleProject(selectedOption);
                        }
                      }}
                      value={
                        selprojectname
                          ? {
                              label: selprojectname,
                              value: selprojectname,
                            }
                          : { label: "", value: "" }
                      }
                      options={[
                        { value: "", label: "" },
                        ...listofprojectnames.map((item) => ({
                          value: item.PROJECTNAME,
                          label: item.PROJECTNAME,
                        })),
                      ]}
                      isDisabled={tableflag === 1}
                    />
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Unit Number</label>
                  </p>
                  <div>
                    <Select
                      styles={customStyless}
                      onChange={(e) => {
                        if (e.value === "") {
                          alert("Kindly Supply proper Unit Id");
                          setselunitno("");
                        } else {
                          setselunitno(e.value);
                          handleUnitNo(e);
                        }
                      }}
                      value={
                        selunitno
                          ? { label: selunitno, value: selunitno }
                          : { label: "", value: "" }
                      }
                      options={[
                        { value: "", label: "" },
                        ...unitlist.map((item) => ({
                          value: item.UNITNO,
                          label: item.UNITNO,
                        })),
                      ]}
                      isDisabled={tableflag === 1}
                    />
                  </div>
                </div>
              </aside>
              <aside className="aside__new__garage PropertyUnitLeases__width">
                <div>
                  <p>
                    <label className="garage_id_">Sub Unit Id </label>
                  </p>
                  <div>
                    <input
                      className="input_1"
                      required
                      value={subunitId}
                      readOnly
                      disabled={tableflag === 1}
                    ></input>
                  </div>
                </div>
                <div>
                  <p>
                    <label className="garage_id_">Sub Unit Number </label>
                  </p>
                  <div>
                    <input
                      className="input_1"
                      required
                      value={subunitnumber}
                      onChange={(e) => {
                        setsubunitnumber(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </aside>

              <div className="savebutton ">
                <button
                  className="buttonunit"
                  onClick={() => {
                    clearallinputs();
                    settableflag(0);
                  }}
                >
                  New Record
                </button>
                <button
                  className="buttonunit ml-5"
                  onClick={async () => {
                    if (!selprojectname) {
                      alert("Kindly Supply Project Name");
                    } else if (!unitid) {
                      alert("Kindly Supply Unit Number");
                    } else if (!subunitnumber) {
                      alert("Kindly Supply sub unit number");
                    } else {
                      await SaveSubUnits();
                      clearallinputs();
                      getListOfProjectIds();
                      getListOfUnitsUnderProperties();
                      settableflag(0);
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <div className="searchhhhhh">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>{" "}
        <div className="search__icon">
          {" "}
          <FaSearch />
        </div>
      </div>
      <div className="service_detials">
        <table className="table_1">
          <thead>
            <tr>
              <td>PROJECT NAME</td>
              <td style={{ textAlign: "right", width: "90px" }}>UNIT NO</td>
              <td style={{ textAlign: "right", paddingRight: "80px" }}>
                SUB UNIT ID
              </td>
              <td>SUB UNIT NO</td>
              <td>EDIT</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => {
              return (
                <tr
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <td>{item.PROJECTNAME ? item.PROJECTNAME : "NA"}</td>
                  <td style={{ textAlign: "right" }}>
                    {item.UNITNUMBER ? item.UNITNUMBER : ""}
                  </td>

                  <td style={{ textAlign: "right", paddingRight: "80px" }}>
                    {item.SUBUNITID ? item.SUBUNITID : "NA"}
                  </td>

                  <td style={{ textAlign: "left" }}>
                    {item.SUBUNITNO ? item.SUBUNITNO : "NA"}
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "22px",
                      textAlign: "right",
                    }}
                    onClick={() => {
                      clearallinputs();
                      FetchTableValuesInFields(item);
                      scrollToSection();
                    }}
                  >
                    <BiSolidEditAlt />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SubUnitMaster;
