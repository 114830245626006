import React, { useState, useEffect } from "react";
import Navbar from "../../Pages/NavbarClient/ClientMenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import Loading from "../../Components/Loading/Loading";
import { IoMdClose } from "react-icons/io";
import { FaStar } from "react-icons/fa6";
import { VscFeedback } from "react-icons/vsc";
function TicketCreationClinet() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const sessiontoken = window.sessionStorage.getItem("session-token");
  console.log(menu);
  const [ticketid, setticketid] = useState("");
  const [ticketidForReview, setticketidForReview] = useState("");
  const [ticketNarrationForReview, setNarrationForReview] = useState("");
  const [ticketdate, setticketdate] = useState(new Date().toISOString());
  const [date, setdate] = useState(new Date().toISOString());
  const [commonareaorunit, setcommonareaorunit] = useState("");
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [selprojectid, setSelProjectId] = useState("");
  const [selprojectname, setselprojectname] = useState("");
  const [problemreported, setproblemreported] = useState("");
  const [selclientcode, setSelClientCode] = useState("");
  const [selclientname, setselclientname] = useState("");
  const [selunitid, setselunitid] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [createdby, setCreatedBy] = useState("");
  const [ticketlist, setticketlist] = useState([]);
  const [search, setSearch] = useState("");
  const state = useSelector((state) => state.changeTheState);
  console.log(state.user.userCode);
  const loginAs = state.user.emailId.split("@")[0];
  const [jobstatus, setjobstatus] = useState("");
  const [reportedby, setreportedby] = useState("");
  const [startdatesort, setStartdatesort] = useState(
    new Date(new Date().getFullYear(), 0, 2).toISOString()
  );
  const [enddatesort, setEnddatesort] = useState(new Date().toISOString());
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
  const [rating, setRating] = useState(0);

  const handleStarClick = (clickedRating) => {
    setRating(clickedRating);
  };

  const getStarColor = (star) => {
    if (star <= rating) {
      switch (rating) {
        case 1:
          return "red";
        case 2:
          return "orange";
        case 3:
          return "yellow";
        case 4:
          return "lightgreen";
        case 5:
          return "green";
        default:
          return "white"; // default color for unknown cases
      }
    }
    return "gray"; // default color for empty stars
  };
  useEffect(() => {
    setCreatedBy(loginAs);
    getnewProjectid();
    getAllDetails();
    ListOfTickets();
  }, []);

  const filteredData = ticketlist.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const Date = item.TICKETDATE;

    if (searchData.length === 0 && !startdatesort && !enddatesort) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["TICKETID", "PROPERTYID", "UNITNO", "PROBLEMREPORTED"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    const dateMatch =
      (!startdatesort || Date >= startdatesort) &&
      (!enddatesort || Date <= enddatesort);

    return textMatch && dateMatch;
  });

  const FetchTableValuesInFields = (item) => {
    setticketid(item.TICKETID);
    setticketdate(item.TICKETDATE);
    setcommonareaorunit(item.COMMONAREAORUNIT);
    setSelProjectId(item.PROPERTYID);
    setselunitid(item.UNITNO);
    setSelClientCode(item.CLIENTCODE);
    setselclientname(item.CLIENTNAME);
    setproblemreported(item.PROBLEMREPORTED);
    setreportedby(item.REPORTEDBY);
  };
  const FetchTableValuesForReview = (item) => {
    console.log(item.RATING, "RATINGRATINGRATING");
    if (item.RATING === null) {
      setticketidForReview(item.TICKETID);
      if (ticketidForReview) {
        openPopup();
      }
    } else {
      alert("Feedback already given thank you.");
    }
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const clearallinputs = () => {
    // setticketid("");
    setticketdate(new Date().toISOString());
    // setcommonareaorunit("");
    // setSelProjectId("");
    // setselprojectname("");
    // setselunitid("");
    // setSelClientCode("");
    // setselclientname("");
    setproblemreported("");
    // setreportedby("");
  };

  function handleCreateTask(value) {
    navigate("/JobIdCreation", { state: { value } });
  }

  const getAllDetails = () => {
    axios
      .post(
        `${BASEURL}/getclentunitidandpropid`,
        {
          CLIENTCODE: state.user.userCode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setSelProjectId(response.data[0].PROJECTID);
        setselunitid(response.data[0].UNITID);
        setselclientname(response.data[0].CLIENTNAME);
        setSelClientCode(response.data[0].CLIENTCODE);
        setreportedby(response.data[0].CLIENTNAME);
        setUnitNumber(response.data[0].UNITNUMBER);
        axios
          .get(`${BASEURL}/ListOfProjectIds`, {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          })
          .then((response1) => {
            const selected = response1.data.find(
              (item) => item.PROJECTID === response.data[0].PROJECTID
            );
            setselprojectname(selected.PROJECTNAME);
            setlistofprojectid(response1.data);
          })
          .catch((error) => {
            console.error("Error fetching List Of Project Ids:", error);
          });
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching Job Id:", error);
        setisloading(false);
      });
  };

  const getnewProjectid = () => {
    axios
      .get(`${BASEURL}/getjobidforpropertytickets`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setticketid(response.data[0].NEXTNO);
        // setticketidForReview(response?.data[0].NEXTNO);
      })
      .catch((error) => {
        console.error("Error fetching Job Id:", error);
      });
  };

  const ListOfTickets = () => {
    axios
      .post(
        `${BASEURL}/propertyticketlistbyclientcode`,
        {
          CLIENTCODE: state.user.userCode,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setticketlist(response.data);
        console.log(response.data, "setticketlist");
      })
      .catch((error) => {
        console.error("Error fetching List Of Tickets:", error);
      });
  };

  //   Save ----------------------------------------
  async function Savedetails() {
    try {
      const response = await axios.post(
        `${BASEURL}/saveticketcreation`,
        {
          JOBID: ticketid,
          REPORTEDDATE: ticketdate,
          COMMONAREAORUNIT: "UNIT",
          PROPERTYID: selprojectid,
          UNITNO: selunitid,
          CLIENTCODE: selclientcode,
          PROBLEMREPORTED: problemreported,
          PROBLEMREPORTEDBY: reportedby,
          JOBSTATUS: "LOGGED IN",
          JOBSTATUSDATE: date,
          JOBSTATUSUPDATEDBY: createdby,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
    } catch (error) {
      console.error(`error saving details `);
    }
  }
  async function submitFeedback() {
    if (ticketNarrationForReview === "") {
      alert("Please write narration");
    } else if (rating < 1) {
      alert("Please provide rating");
    } else {
      const isConfirmed = window.confirm("Are you sure you want to submit?");

      if (isConfirmed) {
        console.log(ticketidForReview, "ticketidForReview");
        console.log(ticketNarrationForReview, "ticketNarrationForReview");
        console.log(rating, "rating");
        closePopup();

        try {
          const response = await axios.post(
            `${BASEURL}/insertclientfeedback`,
            {
              JOBID: ticketidForReview,
              FEEDBACKBYCUSTOMER: ticketNarrationForReview,
              RATINGSTAR: rating,
              FEEDBACKDATE: date,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
          alert(response.data.message);
        } catch (error) {
          console.error(`Error saving details`);
        }
      } else {
        // User canceled submission
        console.log("Submission canceled");
      }
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main">
                <h1 className="header__of__page">
                  {menu ? menu : "PROCESS TICKET"}
                </h1>
              </header>

              <section className="section__newgarage">
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Ticket ID</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={ticketid}
                        readOnly
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Ticket date</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        type="date"
                        value={ticketdate.split("T")[0]}
                        onChange={(e) => {
                          if (e.target.value > new Date().toISOString()) {
                            alert(
                              "Ticket date can not be greater than Current Date"
                            );
                          } else {
                            setticketdate(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Project Name</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={selprojectname}
                        readOnly
                      />
                    </div>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">Unit No</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={unitNumber}
                        readOnly
                      />
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Client Name</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={selclientname}
                        readOnly
                      />
                    </div>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">Problem Reported</label>
                    </p>
                    <div>
                      <textarea
                        class="textmax__width border-solid border-black border-[1px] min-w-[300px] max-w-[300px] min-h-[70px] max-h-[70px]"
                        required
                        value={problemreported}
                        onChange={(e) => {
                          setproblemreported(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </aside>
                {/* <aside className="aside__new__garage PropertyUnitLeases__width"> */}
                {/* <div>
                    <p>
                      <label className="garage_id_">Reported By</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={reportedby}
                        readOnly
                      />
                    </div>
                  </div> */}
                {/* <div>
                    <p>
                      <label className="garage_id_">Job Status</label>
                    </p>
                    <div>
                      <div>
                        <Select
                          styles={customStyless}
                          onChange={(e) => {
                            if (e.value === "") {
                              alert("Kindly choose proper job status");
                            } else {
                              setjobstatus(e.value);
                            }
                          }}
                          value={
                            jobstatus
                              ? {
                                  label: jobstatus,
                                  value: jobstatus,
                                }
                              : { label: "", value: "" }
                          }
                          options={[
                            { value: "", label: "" },
                            { value: "LOGGED IN", label: "LOGGED IN" },
                            {
                              value: "WORK IN PROGRESS",
                              label: "WORK IN PROGRESS",
                            },
                            { value: "CLOSED", label: "CLOSED" },
                          ]}
                        />
                      </div>
                    </div>
                  </div> */}
                {/* </aside> */}
                <aside className="aside__new__garage PropertyUnitLeases__width"></aside>
                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      clearallinputs();
                      getnewProjectid();
                      getAllDetails();
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      if (!ticketid) {
                        alert("Kindly supply Ticket Id");
                      } else if (!selprojectname) {
                        alert("Kindly Supply Project Name");
                      } else if (!selunitid) {
                        alert("Kindly Supply Unit Id");
                      } else if (!selclientname) {
                        alert("Kindly Supply Client name");
                      } else if (!problemreported) {
                        alert("Kindly Provide Problem to be reported");
                      } else if (!reportedby) {
                        alert("Kindly supply Reported By");
                      } else {
                        await Savedetails();
                        // alert("Saved Succesfully");
                        clearallinputs();
                        getnewProjectid();
                        getAllDetails();
                        ListOfTickets();
                      }
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
      <div className="searchhhhhh filter__no__search">
        <p>Search:</p>
        <input
          className="input_1"
          required
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></input>{" "}
        <span className="search__icon search__propert__lease">
          {" "}
          <FaSearch />
        </span>
      </div>
      <div className="searchhhhhh filter__no__search">
        <p>
          <label className="garage_id_">Start date</label>
        </p>
        <div>
          <input
            required
            className="input_1"
            type="date"
            value={startdatesort.split("T")[0]}
            onChange={(e) => setStartdatesort(e.target.value)}
          />
        </div>
        <p>
          <label className="garage_id_">End Date</label>
        </p>
        <div>
          <input
            className="input_1"
            type="date"
            required
            value={enddatesort.split("T")[0]}
            onChange={(e) => setEnddatesort(e.target.value)}
          />
        </div>
      </div>

      <div className="service_detials ">
        <table className="table_1">
          <thead>
            <tr>
              <td
                style={{
                  width: "80px",
                  textAlign: "right",
                  paddingRight: "30",
                }}
              >
                TICKET ID
              </td>
              <td>TICKET DATE</td>
              {/* <td>COMMON AREA OR UNIT</td> */}
              <td
                style={{
                  textAlign: "right",
                  paddingRight: "30",
                }}
              >
                PROPERTY ID
              </td>
              <td
                style={{
                  textAlign: "right",
                  paddingRight: "30",
                }}
              >
                UNIT NO
              </td>
              {/* <td>CLIENT CODE</td>
              <td>CLIENT NAME</td> */}
              <td>PROBLEM REPORTED</td>
              <td>TICKET STATUS </td>
              {/* <td>REPORTED BY</td> */}
              <td>EDIT</td>
              <td>FEEDBACK</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                style={{
                  textTransform: "uppercase",
                }}
              >
                <td
                  style={{
                    width: "100px",
                    textAlign: "right",
                    paddingRight: "30",
                  }}
                >
                  {item.TICKETID ? item.TICKETID : "N/A"}
                </td>
                <td style={{ width: "200px" }}>
                  {item.TICKETDATE
                    ? moment(item.TICKETDATE).format("DD/MM/YYYY")
                    : ""}
                </td>
                {/* <td style={{ textAlign: "right" }}>
                  {item.COMMONAREAORUNIT ? item.COMMONAREAORUNIT : "NA"}
                </td> */}
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "30",
                  }}
                >
                  {item.PROPERTYID ? item.PROPERTYID : "N/A"}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: "40",
                  }}
                >
                  {item.UNITNO ? item.UNITNO : "N/A"}
                </td>
                {/* <td>{item.CLIENTCODE ? item.CLIENTCODE : "NA"}</td>
                <td>{item.CLIENTNAME ? item.CLIENTNAME : "NA"}</td> */}
                <td>{item.PROBLEMREPORTED ? item.PROBLEMREPORTED : "NA"}</td>
                <td>{item.TICKETSTATUS ? item.TICKETSTATUS : "NA"}</td>
                {/* <td>{item.REPORTEDBY ? item.REPORTEDBY : "NA"}</td> */}
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    FetchTableValuesInFields(item);
                    scrollToSection();
                  }}
                >
                  <BiSolidEditAlt />
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                >
                  {item.TICKETSTATUS === "CLOSED" ? (
                    <p
                      onClick={() => {
                        FetchTableValuesForReview(item);
                        scrollToSection();
                      }}
                    >
                      <VscFeedback />
                    </p>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        {isOpen && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              }}
              onClick={closePopup}
            ></div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                background: "white",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                zIndex: 1001,
              }}
              className="w-[90%] h-[450px] lg:w-[500px] lg:h-[400px]"
            >
              <div className="flex justify-between">
                {" "}
                <header className="text-2xl font-bold text-[#002d62] font-serif">
                  <h1 className=" ">FEEDBACK</h1>
                </header>
                <button
                  onClick={closePopup}
                  className=" font-bold text-2xl  text-blue-800"
                >
                  <IoMdClose />
                </button>
              </div>

              <div className="lg:flex my-4">
                <p className="">
                  <label className="garage_id_">Ticket ID :</label>
                </p>
                <p className="">
                  <label className="garage_id_">
                    {" "}
                    &nbsp; {ticketidForReview}
                  </label>
                </p>
              </div>
              <div>
                <p>
                  <label className="garage_id_">Feedback</label>
                </p>
                <div className="mt-1">
                  <textarea
                    class="textmax__width border-solid border-black border-[1px] min-w-[400px] max-w-[400px] min-h-[80px] max-h-[80px]"
                    required
                    onChange={(e) => {
                      setNarrationForReview(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="my-2">
                <p className="font-semibold my-1">Rating : {rating}</p>
                <div className="star-container text-[40px] flex">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className="star cursor-pointer"
                      style={{ color: getStarColor(star) }}
                      onClick={() => handleStarClick(star)}
                    >
                      <FaStar />
                    </span>
                  ))}
                </div>
              </div>
              <button
                onClick={() => {
                  submitFeedback();
                }}
                className="mt-[30px] px-3 py-1 rounded text-white bg-[#002d62]"
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TicketCreationClinet;
