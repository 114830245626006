import React, { useState, useEffect } from "react";
import Navbar1 from "./MenuNavbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { BiSolidEditAlt } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import { FaTasks } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import Loading from "../../Components/Loading/Loading";
import { useLocation } from "react-router-dom";
import { CiViewList } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import "./../PropertyManagementCss/propertyUnitLease.css";
function LEASECONFIRMATION() {
  const navigate = useNavigate();
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  console.log(menu);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [selprojectid, setSelProjectId] = useState("");
  const [selprojectname, setselprojectname] = useState("");
  const [client, setclient] = useState([]);
  const [selclientcode, setSelClientCode] = useState("");
  const [selclientname, setselclientname] = useState("");
  const [leaseno, setLeaseno] = useState("");
  const [Rentamount, setRentAmount] = useState(null);
  const [serviceamt, setServiceAmt] = useState(null);
  const [selunitid, setselunitid] = useState("");
  const [selunitno, setselunitno] = useState("");
  const [projectdepositamount, setprojectdepositamount] = useState(null);
  const [createdby, setCreatedBy] = useState("");
  const [listofprojectid, setlistofprojectid] = useState([]);
  const [unitlist, setlistunit] = useState([]);
  const [createddate, setCreatedDate] = useState(new Date().toISOString());
  const [search, setSearch] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [startdatesort, setStartdatesort] = useState("");
  const [NewLeasenumber, setNewLeasenumber] = useState("");
  const [enddatesort, setEnddatesort] = useState("");
  const state = useSelector((state) => state.changeTheState);
  console.log(state);
  const loginAs = state?.user?.emailId?.split("@")[0];
  const userName = state?.user?.userName;
  console.log(userName);
  const [monthlyrentaltable, setmonthlyrentaltable] = useState([]);
  const [statuss, setstatus] = useState("");
  const [seltransportservices, setseltransportservices] = useState("");
  const [selInternetservices, setselInternetservices] = useState("");
  const [transportcharges, settransportcharges] = useState(null);
  const [Internetcharges, setInternetcharges] = useState(null);
  const [transportbit, settransportbit] = useState(null);
  const [Internetbit, setInternetbit] = useState(null);
  const [transstatus, settransstatus] = useState("");
  const [editflag, seteditflag] = useState(0);
  const [isloading, setisloading] = useState(true);
  const [subunitlist, setsubunitlist] = useState([]);
  const [selsubunitno, setselsubunitno] = useState("");
  const [subunitid, setsubunitid] = useState("");
  const [isDocumentConfirmed, setisDocumentConfirmed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [particularUserData, setParticularUserData] = useState(false);
  const [Companydetails, setCompanydetails] = useState([]);
  const [IsEditing, setIsEditing] = useState(false);
  const [Type, setType] = useState("new");
  const [transportchargesautofetch, settransportchargesautofetch] =
    useState("");
  const [Internetchargesautofetch, setInternetchargesautofetch] = useState("");
  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };
  function handleCaptureMeterReading(
    projectname,
    projectid,
    unitid,
    unitnumber,
    subunitid,
    subunitno,
    leasenumber
  ) {
    navigate("/CaptureMeterReading", {
      state: {
        projectname,
        projectid,
        unitid,
        unitnumber,
        subunitid,
        subunitno,
        leasenumber,
        typeofreading: "ENTRY",
      },
    });
  }

  useEffect(() => {
    scrollToSection();

    setCreatedBy(loginAs);
    getListOfProjectIds();
    ListOfPropertyunitleases();
    getClientcode();
  }, []);

  const filteredData = monthlyrentaltable.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.LEASESTARTDATE;
    const eDate = item.LEASEENDDATE;
    const status = statuss;
    const trans = transstatus;

    if (
      searchData.length === 0 &&
      !startdatesort &&
      !enddatesort &&
      !status &&
      !trans
    ) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "PROJECTNAME",
        "USERNAME",
        "UNITNUMBER",
        "SUBUNITNO",
        "LEASENUMBER",
        "CLIENTNAME",
        "RENTAMOUNT",
        "SERVICEAMOUNT",
        "DEPOSITAMOUNT",
        "TRANSPORTCHARGE",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdatesort || sDate >= startdatesort) &&
      (!enddatesort || eDate <= enddatesort);

    const statusMatch =
      !statuss ||
      (statuss === "CONFIRMED" ? !!item.CONFIRMED : !item.CONFIRMED);

    const TransportstatusMatch =
      !transstatus ||
      (transstatus === "YES"
        ? !!item.TRANSPORTSERVICES
        : !item.TRANSPORTSERVICES);

    return textMatch && dateMatch && statusMatch && TransportstatusMatch;
  });
  console.log(filteredData);
  const FetchTableValuesInFields = (item) => {
    console.log(item);
    seteditflag(1);
    setSelProjectId(item.PROJECTID);
    setselprojectname(item.PROJECTNAME);
    setselunitid(item.UNITID);
    setselunitno(item.UNITNUMBER);
    setLeaseno(item.LEASENUMBER);
    console.log(item.LEASENUMBER);
    setSelClientCode(item.CLIENTCODE);
    setselclientname(item.CLIENTNAME);
    console.log(item.LEASESTARTDATE);
    console.log(item.LEASEENDDATE);
    setStartdate(item.LEASESTARTDATE);
    setEnddate(item.LEASEENDDATE);
    setRentAmount(item.RENTAMOUNT);
    setServiceAmt(item.SERVICEAMOUNT);
    setseltransportservices(item.TRANSPORTSERVICES == 1 ? "YES" : "NO");
    setselInternetservices(item.INTERNETCHARGES == 1 ? "YES" : "NO");
    setInternetbit(item.INTERNETCHARGES);
    settransportbit(item.TRANSPORTSERVICES);
    setInternetcharges(item.INTERNETCHARGES);
    settransportcharges(item.TRANSPORTCHARGE);
    setprojectdepositamount(item.DEPOSITAMOUNT);
    setselsubunitno(item.SUBUNITNO);
    setsubunitid(item.SUBUNITID);
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "270px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const customStylessSelect = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "120px",
      marginTop: "0px",
      minHeight: "initial",
      height: "35px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  const clearallinputs = () => {
    setprojectdepositamount("");
    setSelProjectId("");
    setselunitid("");
    setselunitno("");
    // setLeaseno("");
    setSelClientCode("");
    setselclientname("");
    setStartdate("");
    setEnddate("");
    setRentAmount("");
    setServiceAmt("");
    setseltransportservices("");
    setselInternetservices("");
    setInternetcharges("");
    settransportcharges("");
    setselprojectname("");
    setselsubunitno("");
    setsubunitid("");
  };

  const getListOfUnitIds = (value) => {
    axios
      .post(
        `${BASEURL}/ListOfUnitIds`,
        {
          PROJECTID: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistunit(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project I:", error);
      });
  };

  const getListOfSubunits = (projectid, unitid) => {
    console.log(unitid);
    console.log(projectid);
    axios
      .post(
        `${BASEURL}/fetchsubunitbasedonunitandproject`,
        {
          PROJECTID: projectid,
          UNITID: unitid,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setsubunitlist(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of sub units:", error);
      });
  };

  const getClientcode = () => {
    axios
      .get(`${BASEURL}/Fetchingtheclientcode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setclient(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
  };
  const getListOfProjectIds = () => {
    axios
      .get(`${BASEURL}/ListOfProjectIds`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofprojectid(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
      });
  };

  const ListOfPropertyunitleases = () => {
    axios
      .get(`${BASEURL}/ListOfPropertyunitleases`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setmonthlyrentaltable(response.data);
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
        setisloading(false);
      });
    axios
      .get(`${BASEURL}/getcompanydetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setCompanydetails(response.data[0].ORG_NAME);
        console.log(response.data[0].ORG_NAME);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
        setisloading(false);
      });

    axios
      .get(`${BASEURL}/getnewLeaseNumber`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setLeaseno(response.data[0].NEXTNO);
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
        setisloading(false);
      });

    axios
      .get(`${BASEURL}/getInternetandtransportcharges`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        // setNewLeasenumber(response.data[0]);
        setInternetcharges(response.data[0].INTERNETCHARGES);
        settransportcharges(response.data[0].TRANSPORTCHARGES);
        console.log(response.data[0].INTERNETCHARGES);
        console.log(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Project Idss:", error);
        setisloading(false);
      });
  };

  const handleUnitNo = (selectedOption) => {
    const selected = unitlist.find(
      (item) => item.UNITNO === selectedOption.value
    );
    setselunitid(selected.PROJECTUNIT);
    if (selected.PROJECTUNIT) {
      getListOfSubunits(selprojectid, selected.PROJECTUNIT);
    }
  };

  const handleSubUnitNo = (selectedOption) => {
    const selected = subunitlist.find(
      (item) => item.SUBUNITNO === selectedOption.value
    );
    setsubunitid(selected.SUBUNITID);
  };

  const handleOptionChange = (selectedOption) => {
    const selected = client.find(
      (item) => item.CLIENTNAME === selectedOption.value
    );
    setSelClientCode(selected.CLIENTCODE);
  };

  const handleProject = (selectedOption) => {
    const selected = listofprojectid.find(
      (item) => item.PROJECTNAME === selectedOption.value
    );
    setSelProjectId(selected.PROJECTID);
    if (selected.PROJECTID) {
      getListOfUnitIds(selected.PROJECTID);
    }
  };

  async function Savedetails() {
    console.log("PROJECTID:", selprojectid);
    console.log("PROJECTUNITID:", selunitid);
    console.log("PROJECTSUBUNIT:", subunitid);
    console.log("PROJECTUNITLEASENO:", leaseno);
    console.log("PROJECTUNITCLIENTCODE:", selclientcode);
    console.log("PROJECTUNITRENTSTARTDATE:", startdate);
    console.log("PROJECTUNITRENTENDDATE:", enddate);
    console.log("PROJECTUNITRENTAMOUNT:", Rentamount);
    console.log("PROJECTUNITSERVICECHARGEAMOUNT:", serviceamt);
    console.log("PROJECTUNITSERVICECHARGECREATEDBY:", createdby);
    console.log("PROJECTUNITSERVICECHARGECREATEDDATE:", createddate);
    console.log("PROJECTUNITTTANSPORTCHARGEAPPLICABLE:", transportbit);
    console.log("PROJECTUNITTRANSPORTCHARGEAMOUNT:", transportcharges);
    console.log("PROJECTDEPOSITAMOUNT:", projectdepositamount);
    console.log("INTERNETAPPLICABLE:", Internetbit);
    console.log("INTERNETCHARGES:", Internetcharges);
    if (!IsEditing) {
      console.log("edit");
      if (
        !selprojectid ||
        !selunitid ||
        !subunitid ||
        // !NewLeasenumber?.NEXTNO ||
        !selclientcode ||
        !startdate ||
        !enddate ||
        !Rentamount ||
        !serviceamt ||
        !createdby ||
        !createddate ||
        // !transportbit ||
        // !transportcharges ||
        !projectdepositamount
        // !Internetbit
        // !Internetcharges
      ) {
        alert("All required fields must be filled!");
        return;
      }
      if (!transportbit === 1 || !transportbit === 0) {
        alert("Kindly supply transport services");
        return;
      }
      if (transportbit == 1 && transportcharges < 0) {
        alert("Kindly supply transport charges");
        return;
      }
      if (!transportbit === 1 || !transportbit === 0) {
        alert("Kindly supply transport services");
        return;
      }
      if (Internetbit == 1 && Internetcharges < 0) {
        alert("Kindly supply Internet charges");
        return;
      }
    }
    console.log(Internetbit);
    console.log(Internetcharges);
    console.log(Type);

    try {
      const response = await axios.post(
        `${BASEURL}/Savemonthlyrenttransaction/${Type}`,
        {
          PROJECTID: selprojectid,
          PROJECTUNITID: selunitid,
          PROJECTSUBUNIT: subunitid,
          PROJECTUNITLEASENO: leaseno,
          PROJECTUNITCLIENTCODE: selclientcode,
          PROJECTUNITRENTSTARTDATE: startdate,
          PROJECTUNITRENTENDDATE: enddate,
          PROJECTUNITRENTAMOUNT: Rentamount,
          PROJECTUNITSERVICECHARGEAMOUNT: serviceamt,
          PROJECTUNITSERVICECHARGECREATEDBY: createdby,
          PROJECTUNITSERVICECHARGECREATEDDATE: createddate,
          PROJECTUNITTTANSPORTCHARGEAPPLICABLE: transportbit,
          PROJECTUNITTRANSPORTCHARGEAMOUNT: transportcharges,
          PROJECTDEPOSITAMOUNT: projectdepositamount,
          INTERNETAPPLICABLE: Internetbit,
          INTERNETCHARGES: Internetcharges,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
      console.log(response.data.message);
      setType("new");
    } catch (error) {
      alert(error.response.data.message);
    }
  }
  async function Confirmdetails() {
    console.log(selprojectid);
    console.log(selunitid);
    console.log(leaseno);
    console.log(selclientcode);
    console.log(loginAs);
    try {
      const response = await axios.post(
        `${BASEURL}/Updatethedetailsofpropertyleases`,
        {
          PROJECTID: selprojectid,
          PROJECTUNITID: selunitid,
          PROJECTUNITLEASENO: leaseno,
          LEASENUMBER: leaseno,
          CLIENTCODE: selclientcode,
          CONFIRMEDBY: loginAs,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      alert(response.data.message);
    } catch (error) {
      alert(error.response.data.message);
    }
  }
  function getMoreDetail(usercode) {
    setisloading(true);
    console.log(usercode);
    if (usercode) {
      axios
        .post(
          `${BASEURL}/getClientDetails`,
          {
            CLIENTCODE: usercode,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          openPopup();
          console.log(response);
          setParticularUserData(response.data);
          setisloading(false);
        })
        .catch((error) => {
          console.error("Error fetching List Of Project Idss:", error);
          setisloading(false);
        });
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <Navbar1 />
      <div className="div">
        <div className="center-container_1">
          <div className="form-container_1  ">
            <main className="form_info ">
              <header className="header__of__main flex justify-between items-center">
                <h1 className="header__of__page">
                  {menu ? menu : "Property Unit Leases"}
                </h1>
                <div>
                  <button
                    className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                    onClick={() => {
                      navigate("/ConfirmedLeases");
                    }}
                  >
                    Confirmed leases
                  </button>
                  <button
                    className="px-3 py-1 bg-[#002d62] text-white rounded mx-2"
                    onClick={() => {
                      navigate("/UnConfirmedLeases");
                    }}
                  >
                    Unconfirmed leases
                  </button>
                </div>
              </header>

              <section className="section__newgarage">
                {/* <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Lease No</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={NewLeasenumber}
                      />
                    </div>
                  </div>
                </aside> */}
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Project Name</label>
                    </p>
                    <div>
                      <Select
                        styles={customStyless}
                        onChange={(selectedOption) => {
                          if (selectedOption.value === "") {
                            alert("Kindly Supply proper Project Name");
                            setselprojectname("");
                          } else {
                            setselprojectname(selectedOption.value);
                            handleProject(selectedOption);
                          }
                        }}
                        value={
                          selprojectname
                            ? {
                                label: selprojectname,
                                value: selprojectname,
                              }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          ...listofprojectid.map((item) => ({
                            value: item.PROJECTNAME,
                            label: item.PROJECTNAME,
                          })),
                        ]}
                        isDisabled={editflag === 1}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Unit Number</label>
                    </p>
                    <div>
                      <Select
                        styles={customStyless}
                        onChange={(e) => {
                          if (e.value === "") {
                            alert("Kindly Supply proper Unit Id");
                            setselunitno("");
                          } else {
                            setselunitno(e.value);
                            handleUnitNo(e);
                          }
                        }}
                        value={
                          selunitno
                            ? { label: selunitno, value: selunitno }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          ...unitlist.map((item) => ({
                            value: item.UNITNO,
                            label: item.UNITNO,
                          })),
                        ]}
                        isDisabled={editflag === 1}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Sub Unit Number</label>
                    </p>
                    <div>
                      <Select
                        styles={customStyless}
                        onChange={(e) => {
                          if (e.value === "") {
                            alert("Kindly Supply proper Sub Unit Number");
                            setselsubunitno("");
                          } else {
                            setselsubunitno(e.value);
                            handleSubUnitNo(e);
                          }
                        }}
                        value={
                          selsubunitno
                            ? { label: selsubunitno, value: selsubunitno }
                            : { label: "", value: "" }
                        }
                        options={[
                          { value: "", label: "" },
                          ...subunitlist.map((item) => ({
                            value: item.SUBUNITNO,
                            label: item.SUBUNITNO,
                          })),
                        ]}
                        isDisabled={editflag === 1}
                      />
                    </div>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">Client Name </label>
                    </p>
                    <div>
                      <div>
                        <Select
                          styles={customStyless}
                          onChange={(e) => {
                            if (e.value === "") {
                              alert("Kindly supply proper Client Name");
                              setselclientname("");
                            } else {
                              setselclientname(e.value);
                              handleOptionChange(e);
                            }
                          }}
                          value={
                            selclientname
                              ? { label: selclientname, value: selclientname }
                              : { label: "", value: "" }
                          }
                          options={[
                            { value: "", label: "" },
                            ...client.map((item) => ({
                              value: item.CLIENTNAME,
                              label: item.CLIENTNAME,
                            })),
                          ]}
                          isDisabled={editflag === 1}
                        />
                      </div>
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Lease Number</label>
                    </p>
                    <div>
                      <input
                        required
                        className="input_1"
                        value={leaseno}
                        // onChange={(e) => {
                        //   setLeaseno(e.target.value);
                        // }}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Start date</label>
                    </p>
                    <div>
                      <input
                        disabled={isDocumentConfirmed}
                        required
                        className="input_1"
                        type="date"
                        value={startdate?.split("T")[0]}
                        onChange={(e) => {
                          setStartdate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">End Date</label>
                    </p>
                    <div>
                      <input
                        disabled={isDocumentConfirmed}
                        className="input_1"
                        type="date"
                        required
                        value={enddate?.split("T")[0]}
                        onChange={(e) => {
                          setEnddate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <p>
                      <label className="garage_id_">Rent Amount</label>
                    </p>
                    <div>
                      <input
                        disabled={isDocumentConfirmed}
                        className="input_1"
                        required
                        value={Rentamount}
                        onChange={(e) => {
                          setRentAmount(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Service Amount </label>
                    </p>
                    <div>
                      <input
                        disabled={isDocumentConfirmed}
                        className="input_1"
                        required
                        value={serviceamt}
                        onChange={(e) => {
                          setServiceAmt(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">Deposit Amount </label>
                    </p>
                    <div>
                      <input
                        disabled={isDocumentConfirmed}
                        className="input_1"
                        required
                        type="number"
                        value={projectdepositamount}
                        onChange={(e) => {
                          setprojectdepositamount(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </aside>
                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Transport Services</label>
                    </p>
                    <div>
                      <div>
                        <Select
                          disabled={isDocumentConfirmed}
                          styles={customStyless}
                          onChange={(e) => {
                            if (e.value === "") {
                              alert("Kindly choose proper transport service");
                            } else {
                              setseltransportservices(e.value);
                              if (e.value === "YES") {
                                settransportbit(1);
                              } else {
                                settransportbit(0);
                              }
                            }
                          }}
                          value={
                            seltransportservices
                              ? {
                                  label: seltransportservices,
                                  value: seltransportservices,
                                }
                              : { label: "", value: "" }
                          }
                          options={[
                            {
                              value: "",
                              label: "",
                              isDisabled: isDocumentConfirmed,
                            },
                            {
                              value: "YES",
                              label: "YES",
                              isDisabled: isDocumentConfirmed,
                            },
                            {
                              value: "NO",
                              label: "NO",
                              isDisabled: isDocumentConfirmed,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">Transport Charges</label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={
                          seltransportservices === "YES"
                            ? transportcharges
                            : "0.00"
                        }
                        onChange={(e) => {
                          settransportcharges(e.target.value);
                        }}
                        disabled={
                          isDocumentConfirmed ||
                          seltransportservices !== "YES" || // Disable only when Transport Services is not YES
                          !seltransportservices // Also disable if Transport Services is not selected
                        }
                      />
                    </div>
                  </div>
                </aside>

                <aside className="aside__new__garage PropertyUnitLeases__width">
                  <div>
                    <p>
                      <label className="garage_id_">Internet Services</label>
                    </p>
                    <div>
                      <div>
                        <Select
                          disabled={isDocumentConfirmed}
                          styles={customStyless}
                          onChange={(e) => {
                            if (e.value === "") {
                              alert("Kindly choose proper Internet service");
                            } else {
                              setselInternetservices(e.value);
                              if (e.value === "YES") {
                                setInternetbit(1);
                              } else {
                                setInternetbit(0);
                              }
                            }
                          }}
                          value={
                            selInternetservices
                              ? {
                                  label: selInternetservices,
                                  value: selInternetservices,
                                }
                              : { label: "", value: "" }
                          }
                          options={[
                            {
                              value: "",
                              label: "",
                              isDisabled: isDocumentConfirmed,
                            },
                            {
                              value: "YES",
                              label: "YES",
                              isDisabled: isDocumentConfirmed,
                            },
                            {
                              value: "NO",
                              label: "NO",
                              isDisabled: isDocumentConfirmed,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <p>
                      <label className="garage_id_">Internet Charges</label>
                    </p>
                    <div>
                      <input
                        className="input_1"
                        required
                        value={
                          selInternetservices === "YES"
                            ? Internetcharges
                            : "0.00"
                        }
                        onChange={(e) => {
                          setInternetcharges(e.target.value);
                        }}
                        disabled={
                          isDocumentConfirmed ||
                          selInternetservices !== "YES" ||
                          !selInternetservices
                        }
                      />
                    </div>
                  </div>
                </aside>
                <div className="savebutton">
                  <button
                    className="button"
                    onClick={() => {
                      clearallinputs();
                      seteditflag(0);
                      // setisDocumentConfirmed(!isDocumentConfirmed);
                      setIsEditing(false);
                    }}
                  >
                    New Record
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      // if (!isDocumentConfirmed && !selprojectid) {
                      //   alert("Kindly supply projectname");
                      // } else if (!selunitid) {
                      //   alert("Kindly supply unit number");
                      // } else if (!subunitid) {
                      //   alert("Kindly supply Sub unit Number");
                      // } else if (!startdate) {
                      //   alert("Kindly Supply Start date");
                      // } else if (!enddate) {
                      //   alert("Kindly supply End date");
                      // } else if (!selclientcode) {
                      //   alert("Kindly supply Client Code");
                      // } else if (!Rentamount) {
                      //   alert("Kindly supply Rent amount");
                      // } else if (!serviceamt) {
                      //   alert("Kindly supply service amount");
                      // } else if (!projectdepositamount) {
                      //   alert("Kindly supply Deposit amount");
                      // } else if (!transportbit === 1 || !transportbit === 0) {
                      //   alert("Kindly supply transport services");
                      // } else if (transportbit == 1 && !transportcharges) {
                      //   alert("Kindly supply transport charges");
                      // } else if (!Internetbit === 1 || !Internetbit === 0) {
                      //   alert("Kindly supply Internet services");
                      // } else if (Internetbit == 1 && !Internetcharges) {
                      //   alert("Kindly supply Internet charges");
                      // } else {
                      await Savedetails();
                      seteditflag(0);
                      clearallinputs();
                      getListOfProjectIds();
                      ListOfPropertyunitleases();
                      setIsEditing(false);
                      // }
                    }}
                    disabled={isDocumentConfirmed}
                    style={{
                      backgroundColor: isDocumentConfirmed
                        ? "lightblue"
                        : "#002d62",
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="button"
                    onClick={async () => {
                      console.log(editflag);
                      if (editflag === 1) {
                        if (!selprojectid) {
                          alert("Kindly supply project Id");
                        } else if (!selunitid) {
                          alert("Kindly supply the Unit Id");
                        } else if (!selclientcode) {
                          alert("Kindly Supply the client code");
                        } else {
                          await Confirmdetails();
                          seteditflag(0);
                          clearallinputs();
                          getListOfProjectIds();
                          ListOfPropertyunitleases();
                        }
                      } else {
                        alert("First save the details to confirm");
                      }
                    }}
                    disabled={isDocumentConfirmed}
                    style={{
                      backgroundColor: isDocumentConfirmed
                        ? "lightblue"
                        : "#002d62",
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
      <main className="lease__bothfilte">
        <div className="searc__lease">
          <p>Search:</p>
          <input
            className="input_1 lg:w-[400px] ml-[10px]"
            required
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>{" "}
          <div className="search__icon__lease">
            {" "}
            <FaSearch />
          </div>
          <p>Status:</p>
          <div className="w-[200px]" style={{ fontWeight: 400 }}>
            {" "}
            <Select
              styles={customStylessSelect}
              onChange={(e) => {
                if (e.value === "") {
                  alert("Kindly Supply proper Status");
                  setstatus("");
                } else {
                  setstatus(e.value);
                }
              }}
              value={
                statuss
                  ? { label: statuss, value: statuss }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                { value: "CONFIRMED", label: "CONFIRMED" },
                { value: "NOT CONFIRMED", label: "NOT CONFIRMED" },
              ]}
            />
          </div>
          <p>Transport Status:</p>
          <div className="w-[120px]" style={{ fontWeight: 400 }}>
            {" "}
            <Select
              styles={customStylessSelect}
              onChange={(e) => {
                if (e.value === "") {
                  alert("Kindly Supply Proper Status");
                  settransstatus("");
                } else {
                  settransstatus(e.value);
                }
              }}
              value={
                transstatus
                  ? { label: transstatus, value: transstatus }
                  : { label: "", value: "" }
              }
              options={[
                { value: "", label: "" },
                { value: "YES", label: "YES" },
                { value: "NO", label: "NO" },
              ]}
            />
          </div>
        </div>
      </main>
      <main className="lease__bothfilte">
        <aside className="startand__enddate__lease ">
          <div>
            <p>
              <label className="">Start date</label>
            </p>
            <div>
              <input
                required
                className="input_1"
                type="date"
                value={startdatesort?.split("T")[0]}
                onChange={(e) => setStartdatesort(e.target.value)}
              />
            </div>
          </div>
          <div>
            <p>
              <label className="">End Date</label>
            </p>
            <div>
              <input
                className="input_1"
                type="date"
                required
                value={enddatesort?.split("T")[0]}
                onChange={(e) => setEnddatesort(e.target.value)}
              />
            </div>
          </div>
        </aside>
      </main>

      <div className=" w-full" style={{ overflow: "auto", padding: "10px" }}>
        <table className="table_1 table__size__reducer for__table__width__full">
          <thead>
            <tr>
              <td style={{ width: "150px" }}>PROJECT NAME</td>
              <td style={{ textAlign: "right", width: "100px" }}>UNIT NO</td>
              <td style={{ textAlign: "right", width: "100px" }}>
                SUB UNIT NO
              </td>
              <td style={{ textAlign: "right" }}>LEASE NUMBER</td>
              <td>CLIENT&nbsp; CODE</td>
              <td>CLIENT&nbsp; NAME</td>
              <td>START DATE</td>
              <td>END DATE</td>
              <td style={{ textAlign: "right" }}>RENT AMOUNT</td>
              <td style={{ textAlign: "right" }}>SERVICE AMOUNT</td>
              <td style={{ textAlign: "right" }}>DEPOSIT AMOUNT</td>
              <td>TRANSPORT SERVICES</td>
              <td style={{ textAlign: "right" }}>TRANSPORT CHARGE</td>
              <td>INTERNET SERVICES</td>
              <td style={{ textAlign: "right" }}>INTERNET CHARGE</td>
              <td>STATUS</td>
              <td>EXPIRED</td>
              <td>EDIT</td>
              <td>CAPTURE METER READING (ENTRY)</td>
              <td>RESIDENT AGREEMENT </td>
              <td>OFFICE CONTRACT COPY</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr
                style={{
                  textTransform: "uppercase",
                }}
              >
                <td>{item.PROJECTNAME ? item.PROJECTNAME : "N/A"}</td>
                <td style={{ textAlign: "right" }}>
                  {item.UNITNUMBER ? item.UNITNUMBER : "N/A"}
                </td>
                <td style={{ textAlign: "right" }}>
                  {item.SUBUNITNO ? item.SUBUNITNO : "N/A"}
                </td>
                <td style={{ textAlign: "right" }}>
                  {item.LEASENUMBER ? item.LEASENUMBER : "N/A"}
                </td>
                <td>{item.CLIENTCODE ? item.CLIENTCODE : "NA"}</td>
                <td>{item.CLIENTNAME ? item.CLIENTNAME : "NA"}</td>

                <td style={{ width: "100px" }}>
                  {item?.LEASESTARTDATE
                    ? moment(item.LEASESTARTDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
                <td style={{ width: "100px" }}>
                  {item?.LEASEENDDATE
                    ? moment(item.LEASEENDDATE).format("DD/MM/YYYY")
                    : "N/A"}
                </td>
                <td style={{ textAlign: "right" }}>
                  {item?.RENTAMOUNT
                    ? item.RENTAMOUNT?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </td>
                <td style={{ width: "170px", textAlign: "right" }}>
                  {item?.SERVICEAMOUNT
                    ? item.SERVICEAMOUNT?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </td>
                <td style={{ width: "170px", textAlign: "right" }}>
                  {item?.DEPOSITAMOUNT
                    ? item.DEPOSITAMOUNT?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </td>

                <td>{item?.TRANSPORTSERVICES == 1 ? "YES" : "NO"}</td>
                <td style={{ textAlign: "right" }}>
                  {item.TRANSPORTCHARGE
                    ? item.TRANSPORTCHARGE?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </td>
                <td>{item.INTERNETSERVICES == 1 ? "YES" : "NO"}</td>
                <td style={{ textAlign: "right" }}>
                  {item.INTERNETCHARGES
                    ? item.INTERNETCHARGES.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </td>
                <td>{item.CONFIRMED == 1 ? "CONFIRMED" : "NOT CONFIRMED"}</td>
                <td>
                  {item.EXPIREDLEASE === true ? "EXPIRED" : "NOT EXPIRED"}
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    // if (item.CONFIRMED == 1) {
                    //   setisDocumentConfirmed(true);
                    //   FetchTableValuesInFields(item);
                    //   scrollToSection();
                    // } else {
                    FetchTableValuesInFields(item);
                    scrollToSection();
                    setIsEditing(true);
                    setType("update");
                    // }
                  }}
                >
                  <BiSolidEditAlt />
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={() => {
                    handleCaptureMeterReading(
                      item.PROJECTNAME,
                      item.PROJECTID,
                      item.UNITID,
                      item.UNITNUMBER,
                      item.SUBUNITID,
                      item.SUBUNITNO,
                      item.LEASENUMBER
                    );
                  }}
                >
                  <FaTasks />
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={(e) => {
                    if (
                      userName == "CLIENT PROPERTY MANAGEMENT" &&
                      item.CONFIRMED == 1
                    ) {
                      navigate("/ResidentAgreement1", {
                        state: {
                          inspectionData: item,
                          companyname: Companydetails,
                        },
                      });
                    } else
                      navigate("/ResidentAgreement1", {
                        state: {
                          inspectionData: item,
                          companyname: Companydetails,
                        },
                      });
                  }}
                  // style={{
                  //   cursor: item.CONFIRMED == 1 ? "pointer" : "not-allowed",
                  // }}
                  // disabled={item.CONFIRMED != 1}
                >
                  <FaRegEye />
                </td>
                <td
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "22px",
                  }}
                  onClick={(e) => {
                    navigate("/ResidenceTest", {
                      state: {
                        inspectionData: item,
                        companyname: Companydetails,
                      },
                    });
                  }}
                >
                  <FaRegEye />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        {isOpen && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              }}
            ></div>
            <div
              style={{
                position: "fixed",
                top: "52%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                background: "white",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                zIndex: 1001,
              }}
              className="w-[98%] h-[95vh] lg:h-auto overflow-auto lg:w-[95%] "
            >
              <div className="flex justify-between">
                {" "}
                <header className="text-2xl font-bold text-[#002d62] font-serif">
                  <h1 className=" ">MORE DETAILS</h1>
                </header>
                <button
                  onClick={closePopup}
                  className=" font-bold text-2xl  text-blue-800"
                >
                  <IoMdClose />
                </button>
              </div>

              <section className=" my-4">
                {particularUserData && (
                  <div className="lg:flex flex-wrap gap-1 headers_details w-[100%]">
                    {/* <div className="flex gap-1  pop__up__trip__Edit"> */}
                    <aside>
                      <h1>CLIENT CODE: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.CLIENTCODE
                          ? particularUserData[0]?.CLIENTCODE
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>CLIENT NAME: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.CLIENTNAME
                          ? particularUserData[0].CLIENTNAME
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>USER NAME: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.USERNAME
                          ? particularUserData[0]?.USERNAME
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>PROJECT: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.PROJECT
                          ? particularUserData[0]?.PROJECT
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>UNIT: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.UNIT
                          ? particularUserData[0]?.UNIT
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>UNIT PRICE: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.UNITPRICE
                          ? particularUserData[0]?.UNITPRICE
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>CONTACT NUMBER: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.CONTACTNUMBER
                          ? particularUserData[0]?.CONTACTNUMBER
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>DOB: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.DOB
                          ? particularUserData[0]?.DOB
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>EMERGENCY CONTACT NAME: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.EMERGENCYCONTACTNAME
                          ? particularUserData[0]?.EMERGENCYCONTACTNAME
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>EMERGENCY CONTACT PHONE: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.EMERGENCYCONTACTPHONE
                          ? particularUserData[0]?.EMERGENCYCONTACTPHONE
                          : "N/A"}
                      </p>
                    </aside>

                    <aside>
                      <h1>GUARDIAN CONTACT NAME: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.GUARDIANCONTACTNAME
                          ? particularUserData[0]?.GUARDIANCONTACTNAME
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>GUARDIAN EMAIL: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.GUARDIANEMAIL
                          ? particularUserData[0]?.GUARDIANEMAIL
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>GUARDIAN BOX: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.GUARDIANBOX
                          ? particularUserData[0]?.GUARDIANBOX
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>GUARDIAN PHYSICAL ADDRESS: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.GUARDIANPHYSICALADDRESS
                          ? particularUserData[0]?.GUARDIANPHYSICALADDRESS
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>GUARDIAN HOME ADDRESS: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.GUARDIANHOMEADDRESS
                          ? particularUserData[0]?.GUARDIANHOMEADDRESS
                          : "N/A"}
                      </p>
                    </aside>

                    <aside>
                      <h1>GUARDIAN NAME: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.GUARDIANNAME
                          ? particularUserData[0]?.GUARDIANNAME
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>GUARDIAN ID PASSPORT: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.GUARDIANIDPASSPORT
                          ? particularUserData[0]?.GUARDIANIDPASSPORT
                          : "N/A"}
                      </p>
                    </aside>

                    <aside>
                      <h1>STUDENT EMAIL: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.STUDENTEMAIL
                          ? particularUserData[0]?.STUDENTEMAIL
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>STUDENT ID PASSPORT: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.STUDENTIDPASSPORT
                          ? particularUserData[0]?.STUDENTIDPASSPORT
                          : "N/A"}
                      </p>
                    </aside>
                    <aside>
                      <h1>STUDENT LAST NAME: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.STUDENTLASTNAME
                          ? particularUserData[0]?.STUDENTLASTNAME
                          : "N/A"}
                      </p>
                    </aside>

                    <aside>
                      <h1>STUDENT UNIVERSITY: </h1>
                      <p className="text-red-600">
                        {particularUserData[0]?.STUDENTUNIVERSITY
                          ? particularUserData[0]?.STUDENTUNIVERSITY
                          : "N/A"}
                      </p>
                    </aside>
                  </div>
                )}
              </section>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LEASECONFIRMATION;
